import { Component, ElementRef, HostListener, Input, OnInit, Output, ViewChild, Directive } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
 
import { AppComponent } from "app/app.component";
import {
  AlertPopup,
  config,
  HttpLoader,
  ResolveEmit,
  path,
  BASE_URI,
} from "app/app.config";
import { PlayerService } from "app/components/media-player/media-player-service";
import { SecureFileService } from "app/secure-image/secure-image-service";
import { DataSharingService } from "app/shared/data-sharing.service";
import { SocketService } from "app/socket/socket.service";
import { DatePipe } from "@angular/common";
import { saveAs as importedSaveAs } from "file-saver";
import { ChatService } from "app/components/chat-list/chat.service";
import { viewerType } from "ngx-doc-viewer/document-viewer.component";
import { ELibraryService } from "../../e-library.service";
import { ELibraryComponent } from "../../e-library.component";
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { FileValidationService } from "app/shared/file-validation.service";
import * as _ from "underscore";
import * as moment from "moment";
import { map } from "rxjs/operators";
import { Observable } from "rxjs/Rx";
import { Subscription } from "rxjs/Subscription";
import { AngularEditorConfig } from "@kolkov/angular-editor";
import { EventEmitter } from "protractor";
import { ValidationService } from "app/components/form-validation/validation.service";
import { ChatListComponent } from "app/components/chat-list/chat-list.component";
import { NgxWatermarkOptions } from "ngx-watermark";
import { fromEvent } from "rxjs";
import { ClipboardService } from "ngx-clipboard";
import { threadId } from "worker_threads";
import { CdkDragDrop, CdkDragEnter, CdkDragMove, moveItemInArray } from "@angular/cdk/drag-drop";
import { ConfirmationDialogService } from "app/confirmation-dialog/confirmation-dialog.service";
import { ELibraryDetailsComponent } from "../e-library-details.component";

export interface QuestionType {
  value: string;
  viewValue: string;
}

// @Directive()
@Component({
  selector: "app-e-library-tile",
  templateUrl: "./e-library-tile.component.html",
  styleUrls: ["./e-library-tile.component.scss"],
})
export class ELibraryTileComponent implements OnInit {
  @Input() classFileList;

  @Input() editedAssessmentData;
  @Input() selectedClassList;
  @Input() totalUserCount;
  @Input() getSearchLessonList;
  @Input() userReadCount;
  @Input() maxLength: number = 400;
  @Input() maxHeight = "4rem";
  @Input() groupName;
  // @Input() mediaContent: any;
  @ViewChild("scrollframe") scrollFrame: ElementRef;
  @ViewChild("topicFileInput") public topicFileInput: ElementRef;
  @ViewChild("durationInput") durationInput: ElementRef;
  @ViewChild("surveyCheck") surveyCheck: ElementRef;
  @ViewChild("videoQuiz") videoQuiz: ElementRef;
  @ViewChild("pollsCheck") pollsCheck: ElementRef;
  @ViewChild("videoPlayer") videoplayer: ElementRef;
  @ViewChild("targetUrl") nameInputRef: ElementRef;
  @ViewChild("media") media: ElementRef;

  @ViewChild('dropListContainer') dropListContainer?: ElementRef;

  dropListReceiverElement?: HTMLElement;
  dragDropInfo?: {
    dragIndex: number;
    dropIndex: number;
  };

  @HostListener("document:click", ["$event"]) onDocumentClick(event) {
    this.show = false;
    this.ShareLink = false;
  }

  baseUrl = `${config.PROTOCOL}://${config.HOST}/canstring/${config.VERSION}`;
  shortUrl: string;

  clickit($event, ind) {
    $event.stopPropagation();
    this.dropdownIndex = ind;
    this.show = !this.show;
  }

  show = false;
  ShareLink = false;

  mode = "surveyQuiz";
  getmode = "pollsQuiz";
  getVideoMode = "video";
  getVidQuiz = "quiz";
  beforeQuiz = false;
  quizTimeQ: any;

  modalScrollDistance = config.DEFAULT_SCROLL_DIST;
  modalScrollThrottle = config.SCROLL_THROTTLE;
  OFFSET = config.DEFAULT_OFFSET;
  RECORD_LIMIT = config.DEFAULT_RECORD_LIMIT;
  group_id: any;
  assRadioSel: any;
  assementOptions: any = [];
  assetFormValid = false;
  surveySelectOption = [];
  pollSelectOption = [];
  selectedOptions = [];
  markOptions = [];
  price: number;
  removeAnsClass = [];
  studentNotesRecord = [];
  teachRemarkMarkData = [];
  teachRemarksData = [];
  teacherMark: number = 0;
  testOverallMarks: number = 0;
  public msgIds;
  public videoQuizId;
  videoUrls: string;
  markerSeekBar: any[] = [];

  editedData = [];
  edditedAssesment = [];
  studentResult = [];
  surveyReports = [];
  pollReports = [];
  answers = [];
  usernotes = [];
  uploadAttachFile = true;
  option: any = [];
  totalMarks = 0;
  overallmarks: number;
  percentage: number;
  loggedUserId: number;
  isTakeRetest: boolean;
  isTeacherRemark: boolean;
  maxMarks: number = 0;
  editedMessageId: number;
  public showRadioBtnFlg = false;
  selectedOption = [];
  quizData: any;
  isHidden = false;
  assessmentSubmit = false;
  showHideStudentResult = false;
  individualStudentRecord = [];
  viewStudentQA: any[] = [];
  viewTeacherRemarks: any[] = [];
  viewSubmitQuizData:any[]=[];
  surveySubmit = false;
  pollBtnSubmit = false;
  videoQuizSubmit = false;
  btnContinueVideo:boolean;
  isDownload = false;
  isPublish = false;
  isAllowRetest = false;
  isVideoQuizFound = false;
  discountValidate = false;
  priceValidate = false;
  priceErrMsg = "";
  public options: string = "";

  mediaContent: any = null;
  inputSrc: string;
  thumbSrc: string;
  currentTrack: any;
  playingMedia: string;
  totalRowCount: number;
  showPaginateSpinner = false;
  watermark;
  isNotesLength: any;
  videoQuizRequired: boolean;

  studentIds: number;
  fileIds: number;

  isUserRole: any;
  dropdownIndex: any;
  dropdownIndex1: any;
  activeModalSelectFile = [];
  mesgIds = "";
  selectedReadFile = "";
  showFileLoadSpinner = false;
  showBtnSpinner = false;
  is_question_found = false;
  is_survey_found = false;
  is_polls_found = false;
  is_notes_found = false;
  is_videoQuiz_found: boolean;
  is_notes = false;
  userNotes: any[] = [];
  isChecked = false;
  surveycompleted = "";
  pollCompleted = "";
  defaultAnswerIndex = 0;
  defaultSurAnsIndex = 0;
  defaultPolAnsIndex = 0;
  interactiveVideoQuiz: any[] = [];
  activeQuizModal: boolean;
  getVideoQuizz: any[] = [];

  radioSel: any;
  radioSelected: string;
  radioSelectedString: string;
  isShown = []; // hidden by default

  viewControl = {
    hideExistingFileView: true,
    showHideEditFileUpload: true,
    showAssessmentMarks: true,
    individualStudentResult: true,
    showIndStudentResult: true,
    viewTeacherRemarks: false,
  };

  isMobile: boolean = false;
  width: number = window.innerWidth;
  height: number = window.innerHeight;
  mobileWidth: number = 760;

  resizeObservable$: Observable<Event>;
  resizeSubscription$: Subscription;

  /** View Variables */
  domElementControl = {
    showCreateNewQuestions: false,
    showCreateNewSurQuestion: false,
    showCreateNewPolQuestion: false,
    showCreateNewNotesQuestion: false,
  };

  public subscribers: any = {};

  /***Video title, shared by and created details */
  msgTitle: any;
  messageDescription: any;
  vid_duration: any;
  videoWatermark = "";
  shared_by: any;
  created_at: any;
  currentVideoId: any;
  thumbUrl: string;
  fileType: any;

  mediaData: any;

  currentIndex = 0;
  currentVideo: any = [];
  arrayData: any;
  nextDisabled: boolean = false;
  prevDisabled: boolean = true;
  showHiddenAssessmentModal = [];
  showHideSurveyModal = [];
  showHidePollModal = [];
  getAssessmentData = [];
  getUserTestResult = [];
  getSurveyData = [];
  getPollData = [];
  getAssessmentResult = [];
  getTestScore = [];
  questionCounter = 0; //Tracks question number
  totalUsersCount = 0;

  pager = {
    index: 0,
    size: 1,
    count: 1,
  };

  /** Chat File Size limit */
  chatFileSizeLimit: number = config.MAX_ATTACH_FILE_SIZE;
  chatFileExt = config.TOPIC_EXT;

  upLoadedFileList: any[] = [];
  uploadWatermarkLogo: any[] = [];
  fileDuration: string = "";
  selectedRadio = true;
  getElibraryFileList: any[] = [];
  fileUploadingInProgress = false;
  topicFileExtension = config.TOPIC_EXT;
  topicFileSize = config.TOPIC_FILE_SIZE;
  preUploadedFile: any;
  preUploadedWatermarkFile: any;
  preProfileUrl = path.PRE_PROFILE_URL;
  profileUrl = path.PROFILE_URL;

  file_download = [
    { name: "Yes", value: true },
    { name: "No", value: false },
  ];

  publish = [
    { title: "Yes", value: true },
    { title: "No", value: false },
  ];

  questions: QuestionType[] = [
    { value: "choice", viewValue: "Choice" },
    { value: "text", viewValue: "Text" },
    // { value: "Rating", viewValue: "Rating" }
  ];

  radius: number = 125;
  semicircle: boolean = false;
  pub_article = false;
  activeQuiz = "none";

  assessmentForm: FormGroup;
  editQuizForm: FormGroup;
  surveyQuizForm: FormGroup;
  pollQuizForm: FormGroup;
  userNotesForm: FormGroup;
  studentRemarkForm: FormGroup;

  uploadFileIcons = [
    { name: "pdf", icons: "../../../../assets/images/file_types/pdf.png" },
    { name: "doc", icons: "../../../../assets/images/file_types/doc.png" },
    { name: "docx", icons: "../../../../assets/images/file_types/docx.png" },
    { name: "txt", icons: "../../../../assets/images/file_types/txt.png" },
    { name: "ppt", icons: "../../../../assets/images/file_types/ppt.png" },
    { name: "pptx", icons: "../../../../assets/images/file_types/ppt.png" },
    { name: "pptm", icons: "../../../../assets/images/file_types/ppt.png" },
    { name: "mp3", icons: "../../../../assets/images/file_types/mp3.png" },
    { name: "mp4", icons: "../../../../assets/images/file_types/mp4.png" },
    { name: "wav", icons: "../../../../assets/images/file_types/mp4.png" },
    { name: "avi", icons: "../../../../assets/images/file_types/mp4.png" },
    { name: "png", icons: "../../../../assets/images/file_types/png.png" },
    { name: "jpeg", icons: "../../../../assets/images/file_types/jpg.png" },
    { name: "jpg", icons: "../../../../assets/images/file_types/jpg.png" },
    { name: "flv", icons: "../../../../assets/images/file_types/mp4.png" },
    { name: "html", icons: "../../../../assets/images/file_types/html.png" },
    { name: "psd", icons: "../../../../assets/images/file_types/psd.png" },
    { name: "json", icons: "../../../../assets/images/file_types/json.png" },
    { name: "xlsx", icons: "../../../../assets/images/file_types/xlsx.png" },
    { name: "xls", icons: "../../../../assets/images/file_types/xls.png" },
    { name: "css", icons: "../../../../assets/images/file_types/css.png" },
    { name: "zip", icons: "../../../../assets/images/file_types/zip.png" },
    { name: "sql", icons: "../../../../assets/images/file_types/zip.png" },
    { name: "xml", icons: "../../../../assets/images/file_types/xml.png" },
  ];

  editorConfig: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: "auto",
    maxHeight: "95px",
    width: "auto",
    minWidth: "0",
    translate: "yes",
    enableToolbar: true,
    showToolbar: true,
    placeholder: "Enter Description....",
    defaultParagraphSeparator: "",
    defaultFontName: "",
    defaultFontSize: "",
    fonts: [
      { class: "arial", name: "Arial" },
      { class: "times-new-roman", name: "Times New Roman" },
      { class: "calibri", name: "Calibri" },
      { class: "comic-sans-ms", name: "Comic Sans MS" },
    ],
    uploadWithCredentials: false,
    sanitize: true,
    toolbarPosition: "top",
    toolbarHiddenButtons: [
      ["undo", "redo", "strikeThrough", "subscript", "superscript", "heading"],
      [
        "textColor",
        "backgroundColor",
        "customClasses",
        "link",
        "unlink",
        "insertImage",
        "insertVideo",
        "insertHorizontalRule",
        "removeFormat",
      ],
    ],
  };

  editorNotesConfig: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: "10rem",
    minHeight: "5rem",
    maxHeight: "auto",
    width: "auto",
    minWidth: "0",
    translate: "yes",
    enableToolbar: true,
    showToolbar: true,
    placeholder: "Enter notes here...",
    defaultParagraphSeparator: "",
    defaultFontName: "",
    defaultFontSize: "",
    fonts: [
      { class: "arial", name: "Arial" },
      { class: "times-new-roman", name: "Times New Roman" },
      { class: "calibri", name: "Calibri" },
      { class: "comic-sans-ms", name: "Comic Sans MS" },
    ],
    uploadWithCredentials: false,
    sanitize: true,
    toolbarPosition: "top",
    toolbarHiddenButtons: [
      [
        "undo",
        "redo",
        "strikeThrough",
        "subscript",
        "superscript",
        "indent",
        "outdent",
        "heading",
      ],
      [
        "textColor",
        "backgroundColor",
        "customClasses",
        "link",
        "unlink",
        "insertImage",
        "insertVideo",
        "insertHorizontalRule",
        "removeFormat",
        "toggleEditorMode",
      ],
    ],
  };

  editorIsNotesConfig: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: "15rem",
    minHeight: "5rem",
    maxHeight: "auto",
    width: "auto",
    minWidth: "0",
    translate: "yes",
    enableToolbar: true,
    showToolbar: true,
    placeholder: "Enter notes here...",
    defaultParagraphSeparator: "",
    defaultFontName: "",
    defaultFontSize: "",
    fonts: [
      { class: "arial", name: "Arial" },
      { class: "times-new-roman", name: "Times New Roman" },
      { class: "calibri", name: "Calibri" },
      { class: "comic-sans-ms", name: "Comic Sans MS" },
    ],
    uploadWithCredentials: false,
    sanitize: true,
    toolbarPosition: "top",
    toolbarHiddenButtons: [
      [
        "undo",
        "redo",
        "strikeThrough",
        "subscript",
        "superscript",
        "indent",
        "outdent",
        "heading",
      ],
      [
        "textColor",
        "backgroundColor",
        "customClasses",
        "link",
        "unlink",
        "insertImage",
        "insertVideo",
        "insertHorizontalRule",
        "removeFormat",
        "toggleEditorMode",
      ],
    ],
  };

  DraggableEnable:boolean=false;
  fileLLMformData:FormData=new FormData();
  seletedTab:string;
  showSummarizationPopup:boolean=false;
  SummarizationText:any;
  constructor(
    private httpLoader: HttpLoader,
    private alert: AlertPopup,
    private appcomponent: AppComponent,
    private playerService: PlayerService,
    private router: Router,
    private secureFileService: SecureFileService,
 
    private confirmDialogService:ConfirmationDialogService,
    private socketService: SocketService,
    private dataSharingService: DataSharingService,
    private chatService: ChatService,
    private elibraryService: ELibraryService,
    private elibraryComponent: ELibraryComponent,
    private elibraryDetailComp:ELibraryDetailsComponent,
    private route: ActivatedRoute,
    private fb: FormBuilder,
    private fileValidationService: FileValidationService,
    private validationService: ValidationService,
    private _clipboardService: ClipboardService // private chatListComponent: ChatListComponent
  ) {
    this.editQuizForm = fb.group({
      fileData: [""],
      message: [
        "",
        Validators.compose([
          Validators.required,
          ValidationService.noWhiteSpaceTitleValidator,
        ]),
      ],
      description: [""],
      duration: [""],
      price: [""],
      discount: [""],
      water_mark_title: [""],
      is_download: [""],
      is_publish: [""],
      assessment: fb.array([]),
      survey: fb.array([]),
      polls: fb.array([]),
      notes: fb.array([]),
      min_percentage: [""],
      is_allow_retest: [""],
      random_question: [""],
    });

    this.assessmentForm = fb.group({
      user_answer: ["", Validators.required],
    });

    this.userNotesForm = fb.group({
      question_id: [""],
      user_notes_id: [""],
      notes: ["", Validators.required],
    });

    this.studentRemarkForm = fb.group({
      question_id: [""],
      remarks: [""],
      mark: [""],
    });
  }

  ngOnInit(): void {
    console.log("editedAssessmentData",this.editedAssessmentData);
    this.shortUrl = `${this.baseUrl}/interactionQuestions`;
    this.isUserRole = window.localStorage.getItem("user_role");
    this.isUserRole = parseInt(this.isUserRole.replace(/\"/g, ""));

    this.currentVideo = this.classFileList[this.currentIndex];

    this.route.paramMap.subscribe((x) => {
      this.group_id = x.get("id");
    });

    this.loggedUserId = parseInt(window.localStorage.getItem("user_id"));

    if (this.width <= this.mobileWidth) {
      this.elibraryComponent.viewLibraryList = true;
    } else {
      this.elibraryComponent.viewLibraryList = false;
    }

    this.resizeObservable$ = fromEvent(window, "resize");
    this.resizeSubscription$ = this.resizeObservable$.subscribe((evt) => {
      let windowWith = window.innerWidth;
      if (windowWith <= this.mobileWidth) {
        this.elibraryComponent.viewLibraryList = true;
      } else {
        this.elibraryComponent.viewLibraryList = false;
      }
    });

   this.seletedTab = 'lesson';
  }

  ngAfterViewInit(): void {
    // this.dataSharingService.getLessonsRecords().subscribe(response => {
    //   this.classFileList = response['recordList'];
    // })

    //Called after ngAfterContentInit when the component's view has been initialized. Applies to components only.
    //Add 'implements AfterViewInit' to the class.
    this.subscribers.media = this.elibraryService.getMediaContent().subscribe(
      (response) => {
        if (this.mediaContent && response) {
          if (this.mediaContent.file_path !== response.file_path) {
            this.mediaContent = response;

            this.msgTitle = response.message;
            this.fileType = response.file_type;
            this.messageDescription = response.description;
            this.vid_duration = response.duration;
            this.videoWatermark = response.water_mark_title;
            this.shared_by = response.shared_by;
            this.created_at = response.created_at;
            this.currentVideoId = response.message_id;
            this.watermark = response.watermark;
          }
        } else {
          this.mediaContent = response;
        }
      },
      (error) => console.log(error)
    );

    this.dataSharingService
      .getElibraryDashboardCount()
      .subscribe((data: any) => {
        let lesson = data.recordList;

        let lessons = this.classFileList.find((x) => x === this.currentVideoId);

        if (lesson.length === 1) {
          this.prevDisabled = false;
          this.nextDisabled = false;
        }
      });
  }

  onClickCopyShortUrl(lessonId) {
    try {
      var targetUrl = `${this.baseUrl}/interactionQuestions` + "/" + lessonId;

      var tempInput = document.createElement("input");
      // tempInput.style = "position: absolute; left: -1000px; top: -1000px";
      tempInput.value = targetUrl;
      document.body.appendChild(tempInput);
      tempInput.select();
      document.execCommand("copy");
      document.body.removeChild(tempInput);

      this.alert.showAlertMessage({
        message: "Share link copied Successfully...",
      });
    } catch (error) {
      console.log(error);
    }
  }

  closeShareLink() {
    this.ShareLink = false;
  }

  keyDownHandler(event) {
    if (event.keyCode === 32 && event.target.selectionStart === 0) {
      return false;
    }
  }

  numericOnlyHandler(e) {
    
    if (String.fromCharCode(e.keyCode).match(/[^0-9]/g)) {
      this.alert.showAlertMessage({
        message: "Please enter number value only",
      });
      return false;
    }
  }

  fetchElibraryVideoList() {
    const listLength = this.classFileList.length;
    if (this.totalRowCount > listLength && this.showPaginateSpinner) {
      this.OFFSET += 1;
      this.showPaginateSpinner = true;
    }
  }

  secureFileVideo(messageId, fileData, index) {
    try {
      this.currentIndex = index;
      this.currentVideo = fileData;
      this.prevDisabled = false;
      this.nextDisabled = false;

      if (this.currentIndex === 0) {
        this.prevDisabled = true;
      } else if (this.currentIndex === this.classFileList.length - 1) {
        this.nextDisabled = true;
      }

      this.elibraryService.clear();
      if (fileData.file_type === "video" || fileData.file_type === "audio") {
        if (fileData) {
          this.viewControl.hideExistingFileView = false;
          this.elibraryComponent.viewLibraryList = true;

          this.currentVideoId = messageId;
          this.msgTitle = fileData.message;
          this.fileType = fileData.file_type;
          this.messageDescription = fileData.description;
          this.vid_duration = fileData.duration;
          this.videoWatermark = fileData.water_mark_title;
          this.shared_by = fileData.shared_by;
          this.created_at = fileData.created_at;
          // this.currentVideoId = fileData.message_id;

          const payLoad = {
            file_path: fileData.download_url,
            file_type: fileData.file_type,
            message: fileData.message,
            shared_by: fileData.shared_by,
            created_at: fileData.created_at,
            message_id: fileData.message_id,
            thumb_url: fileData.thumb_url,
            view_Url: fileData.view_Url,
            is_download: fileData.is_download,
            is_question_found: fileData.is_question_found,
            is_survey_found: fileData.is_survey_found,
            is_polls_found: fileData.is_polls_found,
            min_percentage: fileData.min_percentage,
            is_allow_retest: fileData.is_allow_retest
              ? fileData.is_allow_retest
              : false,
            random_question: fileData.random_question,
          };

          this.elibraryService.emitMediaContent(payLoad);
          this.playingMedia = { ...fileData }.download_url;
        }
      } else {
        if (fileData.file_type === "image") {
          this.viewControl.hideExistingFileView = false;
          this.elibraryComponent.viewLibraryList = true;

          this.currentVideoId = messageId;
          this.msgTitle = fileData.message;
          this.fileType = fileData.file_type;
          this.messageDescription = fileData.description;
          this.vid_duration = fileData.duration;
          this.videoWatermark = fileData.water_mark_title;
          this.shared_by = fileData.shared_by;
          this.created_at = fileData.created_at;

          const payLoad = {
            file_path: fileData.download_url,
            file_type: fileData.file_type,
            message: fileData.message,
            shared_by: fileData.shared_by,
            created_at: fileData.created_at,
            message_id: fileData.message_id,
            thumb_url: fileData.thumb_url,
            view_Url: fileData.view_Url,
            is_download: fileData.is_download,
            is_question_found: fileData.is_question_found,
            is_survey_found: fileData.is_survey_found,
            is_polls_found: fileData.is_polls_found,
            min_percentage: fileData.min_percentage,
            is_allow_retest: fileData.is_allow_retest
              ? fileData.is_allow_retest
              : false,
            random_question: fileData.random_question,
          };

          this.elibraryService.emitMediaContent(payLoad);
        }
        // this.playingMedia = {...fileData}.download_url;
      }

      if (this.isUserRole !== 1) {
        let groupId = JSON.parse(this.group_id);

        // this.updateMessageRead(fileData);
        const payLoad = {
          from_user_id: fileData.from_user_id,
          group_id: parseInt(groupId),
          message_id: messageId,
          user_id: parseInt(window.localStorage.getItem("user_id")),
        };

        this.chatService.updateMessageRead(payLoad).subscribe((res: any) => {
          console.log(res);
        });
      }
    } catch (error) {
      console.log(error);
    }
  }

  viewSecureFile(messageId, fileData, index) {
    try {
      this.currentIndex = index;
      this.currentVideo = fileData;
      this.prevDisabled = false;
      this.nextDisabled = false;

      this.is_notes = fileData.is_notes_found;
      this.is_question_found = fileData.is_question_found;
      this.is_survey_found = fileData.is_survey_found;
      this.is_polls_found = fileData.is_polls_found;
      this.msgIds = messageId;
      this.isVideoQuizFound = fileData.is_video_quiz_found;

      this.inputSrc = fileData.view_Url;
      this.thumbSrc = fileData.thumb_Url;

      this.chatService
        .getLoggedUserNotes(messageId)
        .subscribe((response: any) => {
          this.userNotes = response.notes;
          this.interactiveVideoQuiz = response.quiz;
          this.isNotesLength = response.notes.length;

          var positions = this.interactiveVideoQuiz.map((el) => {
            return el.quiz_time;
          });

          this.addVideoMarker(positions, fileData.duration);

          if (response.notes[0] !== null) {
            this.userNotesForm.patchValue({
              user_notes_id: response.notes[0].user_notes_id
                ? response.notes[0].user_notes_id
                : "",
              notes: response.notes[0]["notes"]
                ? response.notes[0]["notes"]
                : "",
            });
          }
        });

      if (this.currentIndex === 0) {
        this.prevDisabled = true;
      } else if (this.currentIndex === this.classFileList.length - 1) {
        this.nextDisabled = true;
      }

      var payLoad = {};
      this.currentVideoId = messageId;
      this.msgTitle = fileData.message;
      this.fileType = fileData.file_type;
      this.messageDescription = fileData.description;
      this.vid_duration = fileData.duration;
      this.videoWatermark = fileData.water_mark_title;
      this.shared_by = fileData.shared_by;
      this.created_at = fileData.created_at;
      this.watermark = fileData.watermark;

      if (fileData.file_type === "video" || fileData.file_type === "audio") {
        if (fileData) {
          this.viewControl.hideExistingFileView = false;
          this.elibraryComponent.viewLibraryList = true;

          this.inputSrc = fileData.view_Url;
          this.thumbSrc = fileData.thumb_Url;

          payLoad = {
            messageId: fileData.message_id,
            file_path: fileData.download_url,
            file_type: fileData.file_type,
            message: fileData.message,
            shared_by: fileData.shared_by,
            created_at: fileData.created_at,
            message_id: fileData.message_id,
            file_ext: fileData.file_ext,
            thumb_url: fileData.thumb_url,
            view_Url: fileData.view_Url,
            description: fileData.description,
            watermark: fileData.watermark,
            is_download: fileData.is_download,
            is_question_found: fileData.is_question_found,
            is_survey_found: fileData.is_survey_found,
            is_polls_found: fileData.is_polls_found,
            download_url: fileData.download_url,
          };
        }
      } else if (fileData.file_type === "image") {
        if (fileData) {
          this.viewControl.hideExistingFileView = false;
          this.elibraryComponent.viewLibraryList = true;
          payLoad = {
            messageId: fileData.message_id,
            file_path: fileData.download_url,
            file_type: fileData.file_type,
            message: fileData.message,
            shared_by: fileData.shared_by,
            created_at: fileData.created_at,
            message_id: fileData.message_id,
            file_ext: fileData.file_ext,
            thumb_url: fileData.thumb_url,
            view_Url: fileData.view_Url,
            description: fileData.description,
            watermark: fileData.watermark,
            is_download: fileData.is_download,
            is_question_found: fileData.is_question_found,
            is_survey_found: fileData.is_survey_found,
            is_polls_found: fileData.is_polls_found,
            download_url: fileData.download_url,
          };
        }
      } else {
        if (fileData) {
          this.viewControl.hideExistingFileView = false;
          this.elibraryComponent.viewLibraryList = true;

          const lessonId = fileData.message_id;

          this.activeModalSelectFile[lessonId] = true;

          var url = fileData.view_Url;

          // this.selectedReadFile = '';

          this.showFileLoadSpinner = true;
          if (url) {
            fileData.startFileDownload = false;
            this.secureFileService.secureReadfile(url).subscribe((blob) => {
              this.showFileLoadSpinner = false;
              this.selectedReadFile = url;
            });
          }

          payLoad = {
            messageId: fileData.message_id,
            file_path: fileData.download_url,
            file_type: fileData.file_type,
            message: fileData.message,
            shared_by: fileData.shared_by,
            created_at: fileData.created_at,
            message_id: fileData.message_id,
            file_ext: fileData.file_ext,
            thumb_url: fileData.thumb_url,
            view_Url: fileData.view_Url,
            description: fileData.description,
            watermark: fileData.watermark,
            is_download: fileData.is_download,
            is_question_found: fileData.is_question_found,
            is_survey_found: fileData.is_survey_found,
            is_polls_found: fileData.is_polls_found,
            download_url: fileData.download_url,
          };
        }
      }

      const options: NgxWatermarkOptions = {
        text: this.watermark,
        width: 350,
        height: 300,
        fontFamily: "Kanit",
        color: "#000",
        alpha: 0.7,
        degree: -45,
        fontSize: "20px",
      };

      if (this.isUserRole !== 1) {
        let groupId = JSON.parse(this.group_id);
        // this.updateMessageRead(fileData);
        const payLoad1 = {
          from_user_id: fileData.from_user_id,
          group_id: parseInt(groupId),
          message_id: messageId,
          user_id: parseInt(window.localStorage.getItem("user_id")),
        };

        this.chatService.updateMessageRead(payLoad1).subscribe((res: any) => {
          // console.log(res)
        });
      }

      this.elibraryService.emitMediaContent(payLoad);

      this.elibraryService.clear();
    } catch (error) {
      console.log(error);
      this.elibraryService.clear();
    }
  }

  secureFileRead(messageId, fileData, index) {
    try {
      this.currentIndex = index;
      this.currentVideo = fileData;
      this.prevDisabled = false;
      this.nextDisabled = false;

      if (this.currentIndex === 0) {
        this.prevDisabled = true;
      } else if (this.currentIndex === this.classFileList.length - 1) {
        this.nextDisabled = true;
      }

      this.elibraryService.clear();
      if (fileData.file_type === "audio") {
        if (fileData) {
          this.viewControl.hideExistingFileView = false;
          this.elibraryComponent.viewLibraryList = true;
          this.inputSrc = fileData.view_Url;
          this.thumbSrc = fileData.thumb_Url;

          this.currentVideoId = messageId;
          this.msgTitle = fileData.message;
          this.fileType = fileData.file_type;
          this.messageDescription = fileData.description;
          this.vid_duration = fileData.duration;
          this.videoWatermark = fileData.water_mark_title;
          this.shared_by = fileData.shared_by;
          this.created_at = fileData.created_at;

          const payLoad = {
            file_path: fileData.download_url,
            file_type: fileData.file_type,
            message: fileData.message,
            shared_by: fileData.shared_by,
            created_at: fileData.created_at,
            message_id: fileData.message_id,
            thumb_url: fileData.thumb_url,
            view_Url: fileData.view_Url,
          };

          this.elibraryService.emitMediaContent(payLoad);
          this.playingMedia = { ...fileData }.download_url;
        }
      } else {
        if (fileData) {
          this.showFileLoadSpinner = true;
          // this.activeModalSelectFile[messageId] = true;
          this.mesgIds = messageId;
          const url = fileData.view_Url;

          this.viewControl.hideExistingFileView = false;
          this.elibraryComponent.viewLibraryList = true;

          this.currentVideoId = messageId;
          this.msgTitle = fileData.message;
          this.fileType = fileData.file_type;
          this.messageDescription = fileData.description;
          this.vid_duration = fileData.duration;
          this.videoWatermark = fileData.water_mark_title;
          this.shared_by = fileData.shared_by;
          this.created_at = fileData.created_at;

          const payLoad = {
            file_path: fileData.download_url,
            file_type: fileData.file_type,
            message: fileData.message,
            shared_by: fileData.shared_by,
            created_at: fileData.created_at,
            message_id: fileData.message_id,
            file_ext: fileData.file_ext,
            thumb_url: fileData.thumb_url,
            view_Url: fileData.view_Url,
          };

          this.elibraryService.emitMediaContent(payLoad);
        }
      }

      if (this.isUserRole !== 1) {
        // this.updateMessageRead(fileData);
        let groupId = JSON.parse(this.group_id);
        const payLoad = {
          from_user_id: fileData.from_user_id,
          group_id: parseInt(groupId),
          message_id: messageId,
          user_id: parseInt(window.localStorage.getItem("user_id")),
        };

        this.chatService.updateMessageRead(payLoad).subscribe((res: any) => {
          console.log(res);
        });
      }
    } catch (error) {
      console.log(error);
    }
  }

  secureFileDownLoad(fileData: any) {
    try {
      const url = fileData.download_url;
      if (url) {
        fileData.startFileDownload = true;

        this.secureFileService.secureFileDownLoad(url).subscribe(
          (blob) => {
            fileData.startFileDownload = false;
            importedSaveAs(blob, fileData.file_id);
          },
          (error) => {
            fileData.startFileDownload = false;
            console.log(error);
          }
        );
      }
    } catch (error) {
      fileData.startFileDownload = false;
      console.log(error);
    }
  }

  secureFileViewed(messageId, fileData) {
    try {
      this.showFileLoadSpinner = true;
      this.activeModalSelectFile[messageId] = true;
      this.mesgIds = messageId;
      var url = fileData.view_Url;
      this.watermark = fileData.watermark;

      console.log(url);

      if (url) {
        // this.showFileLoadSpinner = false;
        fileData.startFileDownload = false;
        this.secureFileService.secureReadfile(url).subscribe((blob) => {
          this.showFileLoadSpinner = false;
          this.selectedReadFile = url;
        });
      }
    } catch (error) {
      this.showFileLoadSpinner = true;
      console.log(error);
    }
  }

  deleteSecureFile(messageId) {
    try {
      this.confirmDialogService.openConfirmDialog('',{
        title:'Confirmation',
        message:'Do you want to delete file?',
        btnOkText:'DELETE',
        btnCancelText:'NO',
        onClose: (data:any) => {
          if(data){
            this.chatService.deleteMessage(messageId).subscribe(
              (response) => {
                this.httpLoader.hideLoader();
                if (response["success"] === false) {
                  this.alert.showAlertMessage({"message":"File deleted successfully"});
                }
                this.alert.showAlertMessage(response);
                this.elibraryComponent.getElibrary(0);
                let position = this.classFileList.findIndex(
                  (x) => x.message_id === messageId
                );

                if (~position) {
                  this.classFileList.splice(position, 1);
                }
              },
              (error) => {
                this.httpLoader.hideLoader();
                this.alert.showAlertMessage(error);
              }
            );
          }
        }	  
    });
      /* this._confirmation
        .create("Confirmation", "Are you sure want to delete File?", {
          confirmText: "DELETE",
        })
        .subscribe((ans: ResolveEmit) => {
          if (ans.resolved) {
            this.chatService.deleteMessage(messageId).subscribe(
              (response) => {
                this.httpLoader.hideLoader();
                if (response["success"] === false) {
                  this.alert.showAlertMessage(response["message"]);
                }
                this.alert.showAlertMessage(response);
                this.elibraryComponent.getElibrary(0);
                let position = this.classFileList.findIndex(
                  (x) => x.message_id === messageId
                );

                if (~position) {
                  this.classFileList.splice(position, 1);
                }
              },
              (error) => {
                this.httpLoader.hideLoader();
                this.alert.showAlertMessage(error);
              }
            );
          }
        }); */
    } catch (error) {
      this.httpLoader.hideLoader();
      console.log(error);
    }
  }

  startPlayListVdo($index, fileData) {
    try {
      var id = fileData.message_id;
      this.currentIndex = $index;
      this.currentVideo = fileData;
      this.prevDisabled = false;
      this.nextDisabled = false;
      this.activeModalSelectFile[id] = false;

      this.elibraryService.clear();

      this.currentVideoId = fileData.message_id;

      this.activeModalSelectFile[id] = false;

      if (this.currentIndex === 0) {
        this.prevDisabled = true;
      } else if (this.currentIndex === this.classFileList.length - 1) {
        this.nextDisabled = true;
      }

      // console.log(fileData);

      if (fileData.file_type === "video" || fileData.file_type === "audio") {
        this.inputSrc = fileData.view_Url;
        this.thumbSrc = fileData.thumb_Url;

        const payLoad = {
          file_path: fileData.download_url,
          file_type: fileData.file_type,
          message: fileData.message,
          shared_by: fileData.shared_by,
          created_at: fileData.created_at,
          message_id: fileData.message_id,
          file_ext: fileData.file_ext,
          thumb_url: fileData.thumb_url,
          view_Url: fileData.view_Url,
          watermark: fileData.watermark,
        };

        this.mediaData = payLoad;
      } else if (fileData.file_type === "image") {
        this.mediaData = fileData;
      } else {
        this.mediaData = fileData;
        var url = fileData.view_Url;
        this.showFileLoadSpinner = true;
        if (url) {
          fileData.startFileDownload = false;
          this.secureFileService.secureReadfile(url).subscribe((blob) => {
            this.showFileLoadSpinner = false;
            this.selectedReadFile = url;
          });
        }
      }

      // console.log(this.mediaData);

      this.elibraryService.emitMediaContent(this.mediaData);
    } catch (error) {
      console.log(error);
    }
  }

  videoPlayerInit(event) {
    const fileSrc = this.mediaContent ? this.mediaContent.file_path : null;
    this.inputSrc = fileSrc.replace("securefileDownload", "secureReadfile");
    // this.videoUrls = fileSrc.replace("securefileDownload", "secureReadfile");
  }

  getVideoPlayingOnTime(event) {
    if (this.isVideoQuizFound && this.isUserRole!==1) {
      var curTimeTrack = 0;

      setTimeout(()=>{
        const timeInMiliseconds = event;

      var sec_num = parseInt(timeInMiliseconds, 10);
      var hours = Math.floor(sec_num / 3600);
      var minutes = Math.floor((sec_num - hours * 3600) / 60);
      var seconds = sec_num - hours * 3600 - minutes * 60;

      var x = hours < 10 ? "0" + hours : hours;
      var y = minutes < 10 ? "0" + minutes : minutes;
      var z = seconds < 10 ? "0" + seconds : seconds;

      var getHours = x + ":" + y + ":" + z;


      var a = getHours.split(":");

      var getSeconds = +a[0] * 60 * 60 + +a[1] * 60 + +a[2];

      var getAtSeconds = getSeconds;

      console.log(getAtSeconds);


      const timeQuiz = this.interactiveVideoQuiz.map((el) => {
        return el;
      });

      const timeArray = this.interactiveVideoQuiz.map((e)=>{
        return e.quiz_time;
      });

      timeQuiz.some((x) => {
        if (getAtSeconds === parseInt(x.quiz_time)) {
          this.media.nativeElement.pause();
          this.videoQuizId = x.quiz_id;
          this.getVideoQuizz = x.questions;
          this.pager.count = this.getVideoQuizz.length;
          this.activeQuiz = "block";
          this.videoQuizRequired = x.is_required;
        }
      });
      },800);

    }
  }

  get filterVideoQuiz() {
    return this.getVideoQuizz
      ? this.getVideoQuizz.slice(
          this.pager.index,
          this.pager.index + this.pager.size
        )
      : [];
  }

  get filterClassFileList() {
    return this.classFileList
      ? this.classFileList.slice(
          this.pager.index,
          this.pager.index + this.pager.size
        )
      : [];
  }

  onSelectVideoQuizRadio(data, options) {
    let isCorrectAnswer: boolean = false;
    var userAnswer = "";
    var mark = 0;

    if (data.answer_id === options.answer_id) {
      isCorrectAnswer = true;
      mark = data.mark;
    }

    data.answer.forEach((x) => {
      if (x.answer_id !== options.answer_id) x.selected = false;
    });

    this.answers = this.answers.filter(
      (x) => x.question_id !== data.question_id
    );

    this.answers.push({
      quiz_id: this.videoQuizId,
      question_id: data.question_id,
      lesson_id: this.msgIds,
      answer_id: options.answer_id,
      question_type: data.question_type,
      is_correct_answer: options.is_correct_answer,
      mark: mark,
      user_answer: options.userAnswer,
      answer_submissionId: new Date().valueOf(),
    });

    if (this.getVideoQuizz.length === this.answers.length) {
      this.videoQuizSubmit = true;
    }
  }

  submitInteractiveQuiz(data) {
    try {
      const payload = {
        lesson_id: this.msgIds,
        answered: this.answers,
        answer_submissionId: new Date().valueOf(),
      };

      this.showBtnSpinner = true;
      this.httpLoader.showLoader();
      this.elibraryService.submitInteractiveQuiz(payload).subscribe(
        (response: any) => {
          this.httpLoader.hideLoader();
          this.showBtnSpinner = false;
          this.alert.showAlertMessage(response);
          this.viewSubmitQuizData = response.quiz_list;
          this.media.nativeElement.play();
          this.activeQuiz = "none";
          // this.btnContinueVideo = response.records['is_questionnaire_completed'];
          this.getVideoQuizz = [];
          this.pager.count=0;
          this.pager.index=0;
        },
        (error) => {
          this.httpLoader.hideLoader();
          this.alert.showAlertMessage(error.error);
        }
      );
    } catch (error) {
      this.httpLoader.hideLoader();
      console.log(error);
    }
  }

  onclickSkipVideoQuiz() {
    var currentTime = this.media.nativeElement.currentTime + 1;
    this.media.nativeElement.play();
    this.activeQuizModal = false;
    this.activeQuiz = "none";
    this.beforeQuiz = true;
    this.getVideoQuizz = [];
    this.pager.count = 0;
    this.pager.index = 0;
  }

  onClickContiVideo() {
    var currentTime = this.media.nativeElement.currentTime + 1;
    this.media.nativeElement.play();
    this.activeQuizModal = false;
    this.activeQuiz = "none";
    this.beforeQuiz = true;
    this.getVideoQuizz = [];
    this.pager.count = 0;
  }

  getPrevNextVideo(index: number) {
    if (index >= 0 && index < this.pager.count) {
      this.pager.index = index;
      this.getVideoMode = "video";
    }
  }

  getQuizPreviewNext(index: number) {
    if (index >= 0 && index < this.pager.count) {
      this.pager.index = index;
      this.getVidQuiz = "quiz";
    }
  }

  getPreVideo(index) {
    this.currentIndex--;

    this.nextDisabled = false;
    if (this.currentIndex === 0) {
      this.prevDisabled = true;
    }
    this.userNotesForm.reset();

    this.currentVideo = this.classFileList[this.currentIndex];

    this.currentVideoId = this.currentVideo.message_id;

    this.is_notes = this.currentVideo.is_notes_found;
    this.msgIds = this.currentVideoId;

    this.chatService
      .getLoggedUserNotes(this.msgIds)
      .subscribe((response: any) => {
        this.userNotes = response.notes;

        this.userNotesForm.patchValue({
          user_notes_id: response.notes[0].user_notes_id
            ? response.notes[0].user_notes_id
            : "",
          notes: response.notes[0]["notes"] ? response.notes[0]["notes"] : "",
        });
      });

    this.elibraryService.clear();

    if (
      this.currentVideo.file_type === "video" ||
      this.currentVideo.file_type === "audio"
    ) {
      const payLoad = {
        file_path: this.currentVideo.download_url
          ? this.currentVideo.download_url
          : null,
        file_type: this.currentVideo.file_type,
        shared_by: this.currentVideo.shared_by,
        message: this.currentVideo.message,
        message_id: this.currentVideo.message_id,
        created_at: this.currentVideo.created_at,
        file_ext: this.currentVideo.file_ext,
        thumb_url: this.currentVideo.thumb_url
          ? this.currentVideo.thumb_url
          : null,
        view_Url: this.currentVideo.view_Url
          ? this.currentVideo.view_Url
          : null,
        watermark: this.currentVideo.watermark,
        is_download: this.currentVideo.is_download,
        is_question_found: this.currentVideo.is_question_found,
        is_survey_found: this.currentVideo.is_survey_found,
        is_polls_found: this.currentVideo.is_polls_found,
        download_url: this.currentVideo.download_url,
      };

      this.mediaData = payLoad;
    } else if (this.currentVideo.file_type === "image") {
      this.mediaData = this.currentVideo;
    } else {
      this.mediaData = this.currentVideo;

      this.showFileLoadSpinner = true;

      var url = this.mediaData.view_Url;

      this.selectedReadFile = "";

      if (url) {
        this.mediaData.startFileDownload = false;
        this.secureFileService.secureReadfile(url).subscribe((blob) => {
          this.showFileLoadSpinner = false;
          this.selectedReadFile = url;
        });
      }
    }

    this.elibraryService.emitMediaContent(this.mediaData);

    if (this.isUserRole !== 1) {
      let groupId = JSON.parse(this.group_id);

      const payLoad = {
        from_user_id: this.currentVideo.from_user_id,
        group_id: parseInt(groupId),
        message_id: this.currentVideo.message_id,
        user_id: parseInt(window.localStorage.getItem("user_id")),
      };

      this.chatService.updateMessageRead(payLoad).subscribe((res: any) => {
        console.log(res);
      });
    }
  }

  getNextVideo(index) {
    this.currentIndex++;
    this.prevDisabled = false;

    if (this.currentIndex === this.classFileList.length - 1) {
      this.nextDisabled = true;
    }

    this.userNotesForm.reset();

    this.currentVideo = this.classFileList[this.currentIndex];
    this.currentVideoId = this.currentVideo.message_id;

    this.is_notes = this.currentVideo.is_notes_found;
    this.msgIds = this.currentVideoId;

    this.chatService
      .getLoggedUserNotes(this.msgIds)
      .subscribe((response: any) => {
        this.userNotes = response.notes;

        this.userNotesForm.patchValue({
          user_notes_id: response.notes[0].user_notes_id
            ? response.notes[0].user_notes_id
            : "",
          notes: response.notes[0]["notes"] ? response.notes[0]["notes"] : "",
        });
      });

    this.elibraryService.clear();

    if (
      this.currentVideo.file_type === "video" ||
      this.currentVideo.file_type === "audio"
    ) {
      this.thumbSrc = this.currentVideo.thumb_Url;
      this.inputSrc = this.currentVideo.view_Url;

      const payLoad = {
        file_path: this.currentVideo.download_url
          ? this.currentVideo.download_url
          : null,
        file_type: this.currentVideo.file_type,
        shared_by: this.currentVideo.shared_by,
        message: this.currentVideo.message,
        message_id: this.currentVideo.message_id,
        created_at: this.currentVideo.created_at,
        file_ext: this.currentVideo.file_ext,
        thumb_url: this.currentVideo.thumb_url
          ? this.currentVideo.thumb_url
          : null,
        view_Url: this.currentVideo.view_Url
          ? this.currentVideo.view_Url
          : null,
        is_download: this.currentVideo.is_download,
        is_question_found: this.currentVideo.is_question_found,
        is_survey_found: this.currentVideo.is_survey_found,
        is_polls_found: this.currentVideo.is_polls_found,
        download_url: this.currentVideo.download_url,
      };

      this.mediaData = payLoad;
    } else if (this.currentVideo.file_type === "image") {
      this.mediaData = this.currentVideo;
    } else {
      this.mediaData = this.currentVideo;

      this.showFileLoadSpinner = true;

      var url = this.mediaData.view_Url;

      if (url) {
        this.mediaData.startFileDownload = false;
        this.secureFileService.secureReadfile(url).subscribe((blob) => {
          this.showFileLoadSpinner = false;
          this.selectedReadFile = url;
        });
      }
    }

    this.elibraryService.emitMediaContent(this.mediaData);

    if (this.isUserRole !== 1) {
      let groupId = JSON.parse(this.group_id);

      const payLoad = {
        from_user_id: this.currentVideo.from_user_id,
        group_id: parseInt(groupId),
        message_id: this.currentVideo.message_id,
        user_id: parseInt(window.localStorage.getItem("user_id")),
      };

      this.chatService.updateMessageRead(payLoad).subscribe((res: any) => {
        console.log(res);
      });
    }
  }

  disabledVideoPlay() {
    let windowWith = window.innerWidth;
    if(windowWith <= this.mobileWidth){
      this.viewControl.hideExistingFileView = true;
     this.elibraryComponent.viewLibraryList = true;
    }else{
    this.viewControl.hideExistingFileView = true;
    this.elibraryComponent.viewLibraryList = false;
    this.interactiveVideoQuiz=[];
    }
  }

  hideReadFile(messageId) {
    console.log(messageId);
    this.activeModalSelectFile[messageId] = false;
  }

  onclickPlayVideo() {
    this.videoplayer.nativeElement.play();
  }

  async fileValidationHanlder(files) {
    try {
      const isValidate = await this.fileValidationService.fileValidation(
        files,
        this.chatFileExt,
        this.chatFileSizeLimit
      );
      return files;
    } catch (err) {
      err.map((li) => {
        const errMsg = {
          message: li,
        };
        // this.alert.showAlertMessage(errMsg);
        this.alert.showAlertMessage({
          message:
            "The file is too large and cannot be uploaded. Please reduce the size of the file and try again (max 1 GB)",
        });
        let button = document.querySelector(".disabledBtn");
        button.setAttribute("disabled", "disabled");
      });
      return false;
    }
  }

  async onFileChange(event) {
    const files = event.target.files;
 
    if(files[0].type=="application/pdf" || files[0].type.indexOf("application/msword") !=-1 || files[0].type.indexOf("application/vnd.openxmlformats") !=-1){
      this.fileLLMformData = new FormData();
      this.fileLLMformData.append('clientid', localStorage.getItem('company_id'));
      this.fileLLMformData.append('file', files[0],files[0].name);
   }

    const vidData = files[0].type.split("/");

    if (vidData[0] === "video" || vidData[0] === "audio") {
      let video = document.createElement(vidData[0]);

      const vid = await this.onloadVideo(files[0], video);
      // var sec = vid.duration;

      var sec = vid["duration"];

      var h,
        m,
        s,
        result = "";
      // HOURs
      h = Math.floor(sec / 3600);
      sec -= h * 3600;
      if (h) {
        result = h < 10 ? "0" + h + ":" : h + ":";
      }
      // MINUTEs
      m = Math.floor((sec % 3600) / 60);
      sec -= m * 60;
      result += m < 10 ? "0" + m + ":" : m + ":";
      // SECONDs
      s = Math.floor((sec % 3600) % 60);
      result += s < 10 ? "0" + s : s;

      var videoDuration = result;
      this.editQuizForm.patchValue({
        duration: videoDuration,
      });
    }

    const fileValid = await this.fileValidationHanlder(files);
    if (fileValid) {
      this.concatFileIntoArray(files);
    }
  }

  concatFileIntoArray(files) {
    if (files && files.length > 0) {
      let button = document.querySelector(".disabledBtn");
      button.setAttribute("disabled", "disabled");
      if (this.upLoadedFileList.length < 1) {
        const formFields = {
          fieldName: "staff_topic",
          activity: "topic",
          // storeType: 'temp'
          // group_id: parseInt(this.group_id)
        };

        let fileEl = null;
        let existFileList = null;
        this.uploadAttachFile = true;
        this.fileUploadingInProgress = true;
        for (let i = 0; i < files.length; i++) {
          const splitArr = files[i].name.split(".");

          files[i].mimeType = splitArr[splitArr.length - 1].toLowerCase();
          files[i].uniqId = Date.now();
          existFileList = [...this.appcomponent.selectedFilesList];
          if (fileEl) {
            fileEl.files.unshift(files[i]);
            this.upLoadedFileList = fileEl.files;
            if (
              files[i].mimeType === "pdf" ||
              files[i].mimeType === "docx" ||
              files[i].mimeType === "doc" ||
              files[i].mimeType === "ppt" ||
              files[i].mimeType === "pptx" ||
              files[i].mimeType === "pptm" ||
              files[i].mimeType === "txt" ||
              files[i].mimeType === "xml" ||
              files[i].mimeType === "png" ||
              files[i].mimeType === "jpg" ||
              files[i].mimeType === "jpeg" ||
              files[i].mimeType === "xls" ||
              files[i].mimeType === "xlsx"
            ) {
              this.editQuizForm.patchValue({
                duration: "",
              });
            }
            let button = document.querySelector(".disabledBtn");
            button.removeAttribute("disabled");
          } else {
            this.upLoadedFileList.push(files[0]);
            this.fileValidationService
              .fileValidation(files, this.chatFileExt, this.chatFileSizeLimit)
              .then((validResponse) => {
                this.appcomponent
                  .fileUploadWithProgess(formFields, this.upLoadedFileList)
                  .then((fileUpLoadResponse: any) => {
                    for (const key in fileUpLoadResponse) {
                      if (fileUpLoadResponse[key]) {
                        this.preUploadedFile = fileUpLoadResponse[key];
                      }
                    }
                    if (
                      this.preUploadedFile.file_type === "application" ||
                      this.preUploadedFile.file_type === "image" ||
                      this.preUploadedFile.file_type === "text"
                    ) {
                      this.durationInput.nativeElement.value = "";
                      // this.topicFileInput.nativeElement.value = "";
                      this.editQuizForm.patchValue({
                        duration: "",
                      });
                    }
                    this.upLoadedFileList = [];
                    let button = document.querySelector(".disabledBtn");
                    button.removeAttribute("disabled");
                  })
                  .catch((error) => {
                    this.upLoadedFileList = [];
                    // this.topicFileInput.nativeElement.value = "";
                    this.alert.showAlertMessage(error.error);
                  });
              })
              .catch((err) => {
                // this.topicFileInput.nativeElement.value = "";
                this.upLoadedFileList = [];
                err.map((li) => this.alert.showAlertMessage(li));
              });
          }
        }
      } else {
        this.alert.showAlertMessage({
          message: "File seletion limit reached.",
        });
      }
    }
  }

  onloadVideo(files, video) {
    return new Promise((resolve, reject) => {
      try {
        video.preload = "metadata";
        video.onloadedmetadata = function () {
          resolve(this);
        };
        video.src = window.URL.createObjectURL(files);
      } catch (event) {
        reject(event);
      }
    });
  }

  removeExitingFile(data) {
    try {
      this.removeFileFromEditList(data);
      this.upLoadedFileList = [];
      this.preUploadedFile = null;
      this.durationInput.nativeElement.value = "";
      this.fileDuration = null;
      this.uploadAttachFile = false;
    } catch (error) {
      console.log(error);
    }
  }

  removeUploadFile(data, duration, file_id) {
    try {
      this.removeFileFromList(data, duration);
      this.upLoadedFileList = [];
      this.preUploadedFile = null;
      this.topicFileInput.nativeElement.value = "";
      // this.durationInput.nativeElement.value = "";
      this.fileDuration = null;
      this.uploadAttachFile = false;
    } catch (error) {
      console.log(error);
    }
  }

  removeFileFromList(val, videoDuration) {
    const data = val[0];
    this.editQuizForm.patchValue({
      duration: "",
    });
    this.uploadAttachFile = false;
    this.durationInput.nativeElement.value = "";
    if (this.upLoadedFileList.length > 0) {
      const newFileList = this.upLoadedFileList;
      const currentFiles = newFileList.filter(
        (fl) => fl.uniqId !== data.uniqId
      );
      const paramId = +this.route.snapshot.paramMap.get("id");
      let fileEl = null;
      const existFileList = this.appcomponent.selectedFilesList;
      fileEl = existFileList.find((el) => el.group_id === paramId);
      if (fileEl) {
        fileEl.files = currentFiles;
      }
      this.upLoadedFileList = currentFiles;
      this.appcomponent.selectedFilesList = existFileList;
    }
  }

  removeFileFromEditList(val) {
    const data = val[0];

    if (this.upLoadedFileList.length > 0) {
      const newFileList = this.upLoadedFileList;
      const currentFiles = newFileList.filter(
        (fl) => fl.uniqId !== data.uniqId
      );
      const paramId = +this.route.snapshot.paramMap.get("id");
      let fileEl = null;
      const existFileList = this.appcomponent.selectedFilesList;
      fileEl = existFileList.find((el) => el.group_id === paramId);

      if (fileEl) {
        fileEl.files = currentFiles;
      }
      this.upLoadedFileList = currentFiles;
      this.appcomponent.selectedFilesList = existFileList;
    }
  }

  hideEditModal(data, duration) {
    let windowWith = window.innerWidth;
    if(windowWith <= this.mobileWidth){
      this.viewControl.showHideEditFileUpload = true;
      this.elibraryComponent.viewLibraryList = true;
    }else{
      this.removeFileFromList(data, duration);
      this.elibraryDetailComp.activeFileUploadHeader = false;
      this.viewControl.showHideEditFileUpload = true;
      this.elibraryComponent.viewLibraryList = false;
      this.preUploadedFile = null;
      this.upLoadedFileList = [];
      this.uploadAttachFile = true;
      this.editQuizForm.reset();
      this.showBtnSpinner = false;
      this.clearFormArray();
    }
    
  }

  openTab(evt, tabName) {
 
    var i, x, tablinks;
   
    this.seletedTab= tabName;
   

    // x = document.getElementsByClassName("content-tab");
    // for (i = 0; i < x.length; i++) {
    //     x[i].style.display = "none";
    // }
    // tablinks = document.getElementsByClassName("tab");
    // for (i = 0; i < x.length; i++) {
    //   tablinks[i].className ="";
    //     tablinks[i].className = "tab";
    // }
    // //document.getElementById(tabName).style.display = "flex";
    // evt.currentTarget.className += " is-active";
  }

  /***Assessment Test Quiz */

  assQuestion(): FormArray {
    return this.editQuizForm.get("assessment") as FormArray;
  }

  assQuestAnswers(questIndex: number): FormArray {
    return this.assQuestion().at(questIndex).get("answer") as FormArray;
  }

  newAssQuestion(): FormGroup {
    return this.fb.group({
      question: [
        null,
        Validators.compose([Validators.required, this.noWhitespaceValidator]),
      ],
      question_id: [""],
      mark: [
        null,
        Validators.compose([Validators.required, this.noWhitespaceValidator]),
      ],
      answer: this.fb.array([]),
      answer_type: [null],
      question_type: ["question"],
    });
  }

  onCreateAssQuestion() {
    this.is_question_found = true;
    this.domElementControl.showCreateNewQuestions = true;
    // this.assQuestion().push(this.newAssQuestion());
    // this.assQuestAnswers(this.assQuestion().length - 1).push(this.newAssAnswers());
    let assessmentValue = this.editQuizForm.get("assessment").value;

    if (assessmentValue.length > 0) {
      let lastIndex = assessmentValue[assessmentValue.length - 1];

      let answerType = lastIndex.answer_type;
      var checked = false;
      var IsChecked = false;

      if (answerType === "choice") {
        for (let i = 0; i < lastIndex.answer.length; i++) {
          const correctAnswer = lastIndex.answer[i];
          // if((lastIndex === 'undefined' || lastIndex.length == 0) || correctAnswer.is_correct_answer!==false){
          //   checked = true;
          //   // this.assementError = true;
          //   this.assQuestion().push(this.newAssQuestion());
          //   this.assQuestAnswers(this.assQuestion().length - 1).push(this.newAssAnswers());
          //   return;
          // }
          if (
            lastIndex.question !== null &&
            lastIndex.mark !== null &&
            correctAnswer.answer !== null &&
            correctAnswer.is_correct_answer !== false
          ) {
            checked = true;
            // this.assementError = true;
            this.assQuestion().push(this.newAssQuestion());
            // this.assQuestAnswers(this.assQuestion().length - 1).push(this.newAssAnswers());
            return;
          }
        }

        if (!checked) {
          // this.assementError = false;
          this.alert.showAlertMessage({
            message: "Please enter question answer and select any one answer",
          });
          return false;
        }
      } else {
        if (lastIndex.question !== null && lastIndex.mark !== null) {
          checked = true;
          // this.assementError = true;
          this.assQuestion().push(this.newAssQuestion());
          // this.assQuestAnswers(this.assQuestion().length - 1).push(this.newAssAnswers());
          return;
        }

        if (!checked) {
          // this.assementError = false;
          this.alert.showAlertMessage({
            message: "Please enter question answer and select any one answer",
          });
          return false;
        }
      }
    } else {
      // this.assementError = true;
      this.assQuestion().push(this.newAssQuestion());
      //this.assQuestAnswers(this.assQuestion().length - 1).push(this.newAssAnswers());
    }
    let button = document.querySelector(".disabledBtn");
    button.setAttribute("disabled", "disabled");
  }

  onSelectedType(questions, questIndex) {
    let control = <FormArray>this.editQuizForm.controls.assessment;

    control.value[questIndex]["answer_type"] = questions.value;

    if (questions.value == "choice") {
      this.isShown[questIndex] = true;
      this.removeAnsClass[questIndex] = false;
      this.radioSelectedString = questions.value;
      this.assQuestAnswers(questIndex).push(this.newAssAnswers());
    } else if (questions.value == "text") {
      this.isShown[questIndex] = false;
      this.removeAnsClass[questIndex] = true;
      control.at(questIndex).get("mark").clearValidators();
      this.assQuestAnswers(questIndex).clear();
    }
  }

  newAssAnswers(): FormGroup {
    return this.fb.group({
      answer: [
        null,
        Validators.compose([Validators.required, this.noWhitespaceValidator]),
      ],
      answer_id: [""],
      is_correct_answer: [false],
    });
  }

  onCreateAssOption(questIndex: number) {
    this.assQuestAnswers(questIndex).push(this.newAssAnswers());
    let button = document.querySelector(".disabledBtn");
    button.setAttribute("disabled", "disabled");
  }

  onCheckedAnswer(evetn, choices, questIndex, optionIndex) {
    let control = <FormArray>this.editQuizForm.controls.assessment;
    let answer = control.value[questIndex].answer;
    for (let i = 0; i < answer.length; i++) {
      // const element = answer[i];
      if (i === optionIndex) {
        answer[i].is_correct_answer = true;
      }
    }

    let removeIdx = this.assQuestAnswers(questIndex).value;

    var checked = false;
    for (let i = 0; i < removeIdx.length; i++) {
      const correctAnswer = removeIdx[i];
      if (correctAnswer.is_correct_answer !== false) {
        checked = true;
        this.selectedOptions[questIndex] = false;
        let button = document.querySelector(".disabledBtn");
        button.removeAttribute("disabled");
      }
    }

    let checker = (removeIdx) =>
      removeIdx.every((v) => v.is_correct_answer !== false);

    if (checker && checked) {
      // let button = document.querySelector('.disabledBtn');
      //     button.removeAttribute('disabled');
      this.editQuizForm.markAsDirty();
    }
  }

  removeAssQuestion(questIndex: number) {
    this.assQuestion().removeAt(questIndex);
    let button = document.querySelector(".disabledBtn");
    button.removeAttribute("disabled");
  }

  removeAssOptions(questIndex: number, optionIndex: number) {
    this.assQuestAnswers(questIndex).removeAt(optionIndex);

    let removeIdx = this.assQuestAnswers(questIndex).value;

    var checked = false;
    for (let i = 0; i < removeIdx.length; i++) {
      const correctAnswer = removeIdx[i];
      if (correctAnswer.is_correct_answer !== false) {
        checked = true;
      }
    }

    if (!checked) {
      this.selectedOptions[questIndex] = true;
      this.alert.showAlertMessage({ message: "Please select any one answer" });
      let button = document.querySelector(".disabledBtn");
      button.setAttribute("disabled", "disabled");
      return false;
    }

    let button = document.querySelector(".disabledBtn");
    button.removeAttribute("disabled");
  }

  loadAssessmentQuiz(message_id, question_type) {
    try {
      this.mesgIds = message_id;
      this.viewControl.showIndStudentResult = true;

      let payLoad = {
        group_id: parseInt(this.group_id),
        message_id: message_id,
        question_type: question_type,
      };

      if (question_type === "question") {
        this.showHiddenAssessmentModal[message_id] = true;
        if (this.isUserRole != 1) {
          this.chatService
            .getStudentTestMark(payLoad)
            .subscribe((response: any) => {
              // console.log(response);
              var takeTest = response.takeTest;

              if (takeTest === true) {
                this.viewControl.showAssessmentMarks = true;
                this.loadAssessmentQuestion(payLoad);
              } else {
                this.getUserTestResult = response.userTest;
                this.viewControl.showAssessmentMarks = false;
                this.isTakeRetest = response.retest;
                this.isTeacherRemark = response.is_remarks_found;
                // console.log(this.getAssessmentData, 'studentMark')
              }
            });
        } else {
          this.chatService
            .getStudentResult(message_id, question_type)
            .subscribe((response) => {
              this.studentResult = response["assessment"];
              this.overallmarks = response["overallmark"];
            });
        }
      } else if (question_type === "survey") {
        this.showHideSurveyModal[message_id] = true;

        if (this.isUserRole === 1) {
          this.chatService
            .getSurveyPollResult(message_id, question_type)
            .subscribe((data: any) => {
              this.surveyReports = data.assessment;
              this.pager.count = this.surveyReports.length;
            });
        } else {
          // this.loadAssessmentQuestion(payLoad);
          this.chatService
            .getStudentTestMark(payLoad)
            .subscribe((response: any) => {
              var takeTest = response.takeTest;

              if (takeTest === true) {
                this.viewControl.showAssessmentMarks = true;
                this.loadAssessmentQuestion(payLoad);
              } else {
                this.getUserTestResult = response.userTest;
                this.viewControl.showAssessmentMarks = false;
                this.isTakeRetest = response.retest;
                this.isTeacherRemark = response.is_remarks_found;
                // console.log(this.getAssessmentData, 'studentMark')
              }
            });
        }
      } else if (question_type === "polls") {
        this.showHidePollModal[message_id] = true;

        if (this.isUserRole === 1) {
          const questionType = "polls";
          this.chatService
            .getSurveyPollResult(message_id, questionType)
            .subscribe((data: any) => {
              this.pollReports = data.assessment;
              this.pager.count = this.pollReports.length;
            });
        } else {
          this.chatService
            .getStudentTestMark(payLoad)
            .subscribe((response: any) => {
              var takeTest = response.takeTest;

              if (takeTest === true) {
                this.viewControl.showAssessmentMarks = true;
                this.loadAssessmentQuestion(payLoad);
              } else {
                this.getUserTestResult = response.userTest;
                this.viewControl.showAssessmentMarks = false;
                this.isTakeRetest = response.retest;
                this.isTeacherRemark = response.is_remarks_found;
                // console.log(this.getAssessmentData, 'studentMark')
              }
            });
        }
      }
    } catch (error) {
      console.log(error);
      this.httpLoader.hideLoader();
    }
  }

  onClickTakeRetest(message_id) {
    this.viewControl.showAssessmentMarks = true;

    let payLoad = {
      group_id: parseInt(this.group_id),
      message_id: message_id,
      question_type: "question",
    };

    this.loadAssessmentQuestion(payLoad);
  }

  loadAssessmentQuestion(payLoad) {
    this.chatService.getQuestions(payLoad).subscribe((response: any) => {
      this.getAssessmentData = response.assessment;
      this.pager.count = this.getAssessmentData.length;
    });
  }

  getQuizeDetails(message_id) {
    try {
      const payLoad = {
        group_id: parseInt(this.group_id),
        file_id: message_id,
      };
      this.elibraryDetailComp.activeFileUploadHeader = true;
      this.viewControl.showHideEditFileUpload = false;
      this.elibraryComponent.viewLibraryList = true;
      this.activeModalSelectFile[message_id] = true;

      this.chatService.editUploadFileList(payLoad).subscribe(
        (data: any) => {
          this.editedData = data.record;
          this.editedMessageId = data.record ? data.record.message_id : 0;

          let assData = this.editedData;

          assData["assessment"].forEach((ass) => {
            const question: FormGroup = this.newAssQuestion();

            this.assQuestion().push(question);

            ass.answer.forEach((opt) => {
              const answer = this.newAssAnswers();
              (question.get("answer") as FormArray).push(answer);
            });
          });

          assData["survey"].forEach((sur) => {
            const question: FormGroup = this.newSurveyQuestion();

            this.surveyQuestions().push(question);

            sur.answer.forEach((opt) => {
              const answer = this.newSurveyOptions();
              (question.get("answer") as FormArray).push(answer);
            });
          });

          assData["polls"].forEach((pol) => {
            const question: FormGroup = this.newPollQuestion();

            this.pollQuestions().push(question);

            pol.answer.forEach((opt) => {
              const answer = this.newPollOptions();
              (question.get("answer") as FormArray).push(answer);
            });
          });

          assData["notes"].forEach((not) => {
            const question: FormGroup = this.createNotesQuestion();

            this.notesQuestions().push(question);
          });

          this.editQuizForm.patchValue({
            message: data.record.message,
            description: data.record.description,
            duration: data.record.duration,
            price: data.record.price,
            discount: data.record.discount,
            is_download: data.record.is_download,
            is_publish: data.record.is_publish,
            water_mark_title: data.record.water_mark_title,
            assessment: data.record.assessment,
            survey: data.record.survey,
            polls: data.record.polls,
            notes: data.record.notes,
            min_percentage: data.record.min_percentage,
            is_allow_retest: data.record.is_allow_retest,
            random_question: data.record.random_question,
          });

          this.isAllowRetest = data.record.is_allow_retest;

          let assRecord = data.record.assessment;

          for (let i = 0; i < assRecord.length; i++) {
            const element = assRecord[i].answer_type;

            if (element === "choice") {
              this.isShown[i] = true;
              this.removeAnsClass[i] = false;
            } else if (element === "text") {
              this.removeAnsClass[i] = true;
            }
          }

          let index = 0; // or 1 or 2
          // this.clearFormArry();

          if (data.record.assessment.length > 0) {
            this.domElementControl.showCreateNewQuestions = true;
            this.is_question_found = true;
          }

          if (data.record.survey.length > 0) {
            this.domElementControl.showCreateNewSurQuestion = true;
            this.is_survey_found = true;
          }

          if (data.record.polls.length > 0) {
            this.domElementControl.showCreateNewPolQuestion = true;
            this.is_polls_found = true;
          }

          if (data.record.notes.length > 0) {
            this.is_notes_found = data.record.is_notes_found;
            this.domElementControl.showCreateNewNotesQuestion = true;
          }
        },
        (error) => {
          this.httpLoader.hideLoader();
          console.log(error);
        }
      );
    } catch (error) {
      this.httpLoader.hideLoader();
      console.log(error);
    }
  }

  calculateTotalMarks(getMarks) {
    for (let i = 0; i < getMarks.length; i++) {
      this.maxMarks += Number(getMarks[i]["mark"]);
    }
    return this.maxMarks;
  }

  onSelectedAssRadio(data, option, formFields) {
    let isCorrectAnswer: boolean = false;
    var userAnswer = "";

    if (data.answer_id === option.answer_id) {
      isCorrectAnswer = true;
    }

    this.answers = this.answers.filter(
      (x) => x.question_id !== data.question_id
    );

    if (data.answer_type === "text") {
      userAnswer = formFields.user_answer;
    } else {
      userAnswer = "";
    }

    this.answers.push({
      question_id: data.question_id,
      answer_id: option.answer_id,
      is_correct_answer: isCorrectAnswer,
      user_answer: userAnswer,
    });

    if (this.getAssessmentData.length === this.answers.length) {
      this.assessmentSubmit = true;
    }
  }

  hideModalAssessment(message_id) {
    this.showHiddenAssessmentModal[message_id] = false;
    this.individualStudentRecord = [];
    this.viewControl.individualStudentResult = true;
    this.viewControl.viewTeacherRemarks = false;
  }

  hideAssessmentModal(message_id) {
    try {
      if (this.isUserRole !== 1) {
        this.confirmDialogService.openConfirmDialog('',{
          title:'Confirmation',
          message:'Are you sure you want to cancel? If you cancel, you have to retake the test from the beginning',
          btnOkText:'YES',
          btnCancelText:'NO',
          onClose: (data:any) => {
            if(data){
              this.showHiddenAssessmentModal[message_id] = false;
              this.assessmentSubmit = false;
              this.answers = [];
              this.assessmentForm.reset();
            }
          }	  
      });
       /* this._confirmation
          .create(
            "Confirmation",
            "Are you sure you want to cancel ? If you cancel, you have to retake the test from the beginning",
            { confirmText: "Yes, Cancel" }
          )
          .subscribe((ans: ResolveEmit) => {
            if (ans.resolved) {
              this.showHiddenAssessmentModal[message_id] = false;
              this.assessmentSubmit = false;
              this.answers = [];
              this.assessmentForm.reset();
            }
          }); */
      }
    } catch (error) {
      this.httpLoader.hideLoader();
      console.log(error);
    }
  }

  /***Survey Quiz */

  surveyQuestions(): FormArray {
    return this.editQuizForm.get("survey") as FormArray;
  }

  surveyOptions(questIndex: number): FormArray {
    return this.surveyQuestions().at(questIndex).get("answer") as FormArray;
  }

  newSurveyQuestion(): FormGroup {
    return this.fb.group({
      question_id: [""],
      question: [
        "",
        Validators.compose([Validators.required, this.noWhitespaceValidator]),
      ],
      answer: this.fb.array([]),
      question_type: ["survey"],
    });
  }

  onCreateSurveyQuestion() {
    this.domElementControl.showCreateNewSurQuestion = true;
    this.is_survey_found = true;
    this.surveyQuestions().push(this.newSurveyQuestion());
    this.surveyOptions(this.surveyQuestions().length - 1).push(
      this.newSurveyOptions()
    );
    let button = document.querySelector(".disabledBtn");
    button.setAttribute("disabled", "disabled");
  }

  onRemoveSurveyQuestion(questIndex) {
    this.surveyQuestions().removeAt(questIndex);
    let button = document.querySelector(".disabledBtn");
    button.removeAttribute("disabled");
  }

  newSurveyOptions(): FormGroup {
    return this.fb.group({
      answer_id: [""],
      answer: [
        "",
        Validators.compose([Validators.required, this.noWhitespaceValidator]),
      ],
    });
  }

  oncreateSurveyOptions(questIndex: number) {
    this.surveyOptions(questIndex).push(this.newSurveyOptions());
  }

  removeSurveyOptions(questIndex, choiceIndex) {
    this.surveyOptions(questIndex).removeAt(choiceIndex);
    let button = document.querySelector(".disabledBtn");
    button.removeAttribute("disabled");
  }

  get filteredQuestions() {
    return this.getAssessmentData
      ? this.getAssessmentData.slice(
          this.pager.index,
          this.pager.index + this.pager.size
        )
      : [];
  }

  get filterReportSurvey() {
    return this.surveyReports
      ? this.surveyReports.slice(
          this.pager.index,
          this.pager.index + this.pager.size
        )
      : [];
  }

  isAnswered(question) {
    return question.options.find((x) => x.selected)
      ? "Answered"
      : "Not Answered";
  }

  isCorrect(question) {
    return question.options.every((x) => x.selected === x.isAnswer)
      ? "correct"
      : "wrong";
  }

  onSelectedSurRadio(data, option) {
    if (data.question_type === "survey") {
      data.answer.forEach((x) => {
        if (x.answer_id !== option.answer_id) x.selected = false;
      });

      this.answers = this.answers.filter(
        (x) => x.question_id !== data.question_id
      );

      this.answers.push({
        question_id: data.question_id,
        answer_id: option.answer_id,
      });

      if (this.getAssessmentData.length === this.answers.length) {
        this.surveySubmit = true;
      }
    } else if (data.question_type === "polls") {
      data.answer.forEach((x) => {
        if (x.answer_id !== option.answer_id) x.selected = false;
      });

      this.answers = this.answers.filter(
        (x) => x.question_id !== data.question_id
      );

      this.answers.push({
        question_id: data.question_id,
        answer_id: option.answer_id,
        user_answer: data.user_answer,
      });

      if (this.getAssessmentData.length === this.answers.length) {
        this.pollBtnSubmit = true;
      }
    }
  }

  getPreviewNext(index: number) {
    if (index >= 0 && index < this.pager.count) {
      this.pager.index = index;
      this.mode = "surveyQuiz";
    }
  }

  hideSurveyQuizModal(message_id) {
    try {
      if (this.isUserRole !== 1) {
        this.confirmDialogService.openConfirmDialog('',{
          title:'Confirmation',
          message:'Are you sure you want to cancel? If you cancel, you have to retake the survey from the beginning',
          btnOkText:'YES',
          btnCancelText:'NO',
          onClose: (data:any) => {
            if(data){
              this.showHideSurveyModal[message_id] = false;
              this.surveySubmit = false;
              this.answers = [];
              this.getAssessmentData = [];
              this.pager.index = 0;
              this.pager.size = 1;
              this.pager.count = 1;
            }
          }	  
      });
       /* this._confirmation
          .create(
            "Confirmation",
            "Are you sure you want to cancel ? If you cancel, you have to retake the survey from the beginning",
            { confirmText: "Yes, Cancel" }
          )
          .subscribe((ans: ResolveEmit) => {
            if (ans.resolved) {
              this.showHideSurveyModal[message_id] = false;
              this.surveySubmit = false;
              this.answers = [];
              this.getAssessmentData = [];
              this.pager.index = 0;
              this.pager.size = 1;
              this.pager.count = 1;
            }
          }); */
      }
    } catch (error) {
      this.httpLoader.hideLoader();
      console.log(error);
    }
  }

  hideSurverModal(message_id) {
    this.showHideSurveyModal[message_id] = false;
    this.pager.index = 0;
    this.pager.size = 1;
    this.pager.count = 1;
  }

  /***Polls Quiz */

  pollQuestions(): FormArray {
    return this.editQuizForm.get("polls") as FormArray;
  }

  pollOptions(questIndex: number): FormArray {
    return this.pollQuestions().at(questIndex).get("answer") as FormArray;
  }

  newPollQuestion(): FormGroup {
    return this.fb.group({
      question_id: [""],
      question: [
        "",
        Validators.compose([Validators.required, this.noWhitespaceValidator]),
      ],
      answer: this.fb.array([]),
      question_type: ["polls"],
    });
  }

  onCreatePollQuestion() {
    this.is_polls_found = true;
    this.domElementControl.showCreateNewPolQuestion = true;
    this.pollQuestions().push(this.newPollQuestion());
    this.pollOptions(this.pollQuestions().length - 1).push(
      this.newPollOptions()
    );
    let button = document.querySelector(".disabledBtn");
    button.setAttribute("disabled", "disabled");
  }

  newPollOptions(): FormGroup {
    return this.fb.group({
      answer_id: [""],
      answer: [
        "",
        Validators.compose([Validators.required, this.noWhitespaceValidator]),
      ],
    });
  }

  onCreatePollOptions(questIndex: number) {
    this.pollOptions(questIndex).push(this.newPollOptions());
    let button = document.querySelector(".disabledBtn");
    button.setAttribute("disabled", "disabled");
  }

  removePollQuestion(index) {
    this.pollQuestions().removeAt(index);
    let button = document.querySelector(".disabledBtn");
    button.removeAttribute("disabled");
  }

  onRemovePollOptions(questIndex, choiceIndex) {
    this.pollOptions(questIndex).removeAt(choiceIndex);
    let button = document.querySelector(".disabledBtn");
    button.removeAttribute("disabled");
  }

  get pollfilterQuestions() {
    return this.getAssessmentData
      ? this.getAssessmentData.slice(
          this.pager.index,
          this.pager.index + this.pager.size
        )
      : [];
  }

  get pollsfilterReports() {
    return this.pollReports
      ? this.pollReports.slice(
          this.pager.index,
          this.pager.index + this.pager.size
        )
      : [];
  }

  onSelectedPollsRadio(data, option) {
    if (data.question_type === "polls") {
      data.answer.forEach((x) => {
        if (x.answer_id !== option.answer_id) x.selected = false;
      });
    }

    this.answers = this.answers.filter(
      (x) => x.question_id !== data.question_id
    );

    this.answers.push({
      question_id: data.question_id,
      answer_id: option.answer_id,
    });

    if (this.getAssessmentData.length === this.answers.length) {
      this.pollBtnSubmit = true;
    }
  }

  getPollNextPrev(index: number) {
    if (index >= 0 && index < this.pager.count) {
      this.pager.index = index;
      this.getmode = "pollsQuiz";
    }
  }

  hidePollActiveModal(message_id) {
    try {
      if (this.isUserRole !== 1) {
        this.confirmDialogService.openConfirmDialog('',{
          title:'Confirmation',
          message:'Are you sure you want to cancel? If you cancel, you have to retake the poll from the beginning',
          btnOkText:'YES',
          btnCancelText:'NO',
          onClose: (data:any) => {
            if(data){
              this.showHidePollModal[message_id] = false;
              this.answers = [];
              this.getAssessmentData = [];
              this.pager.index = 0;
              this.pager.size = 1;
              this.pager.count = 1;
              this.pollBtnSubmit = false;
            }
          }	  
      });		  
       /* this._confirmation
          .create(
            "Confirmation",
            "Are you sure you want to cancel ? If you cancel, you have to retake the poll from the beginning",
            { confirmText: "Yes, Cancel" }
          )
          .subscribe((ans: ResolveEmit) => {
            if (ans.resolved) {
              this.showHidePollModal[message_id] = false;
              this.answers = [];
              this.getAssessmentData = [];
              this.pager.index = 0;
              this.pager.size = 1;
              this.pager.count = 1;
              this.pollBtnSubmit = false;
            }
          }); */
      }
    } catch (error) {
      this.httpLoader.hideLoader();
      console.log(error);
    }
  }

  hidePollModal(message_id) {
    this.showHidePollModal[message_id] = false;
    this.pager.index = 0;
    this.pager.size = 1;
    this.pager.count = 1;
  }

  /****notes */

  notesQuestions(): FormArray {
    return this.editQuizForm.get("notes") as FormArray;
  }

  createNotesQuestion(): FormGroup {
    return this.fb.group({
      question: [
        "",
        Validators.compose([Validators.required, this.noWhitespaceValidator]),
      ],
      question_type: ["notes"],
    });
  }

  onCreateNotesQuestion() {
    this.domElementControl.showCreateNewNotesQuestion = true;
    this.is_notes_found = true;
    this.notesQuestions().push(this.createNotesQuestion());
  }

  removeNotesQuestion(index) {
    this.notesQuestions().removeAt(index);
  }

  submitAssessmentQuiz(data, message_id, question_type) {
    try {
      const payLoad = {
        file_id: message_id,
        answered: this.answers,
        question_type: question_type,
        answer_submissionId: new Date().valueOf(),
      };

      this.showBtnSpinner = true;

      this.chatService.submitAssessment(payLoad).subscribe(
        (response: any) => {
          this.httpLoader.hideLoader();
          this.assessmentForm.reset();
          this.showBtnSpinner = false;
          this.viewControl.showAssessmentMarks = false;
          this.showHideSurveyModal[message_id] = false;
          this.showHidePollModal[message_id] = false;
          this.answers = [];
          this.getAssessmentData.length = 0;
          this.pager.index = 0;
          this.pager.size = 1;
          this.pager.count = 1;

          this.alert.showAlertMessage(response);
          this.isTakeRetest = response.retest;
          this.isTeacherRemark = response.is_remarks_found;
          this.getUserTestResult.push(response.assessment);

          console.log(this.getUserTestResult, "user result");
        },
        (error) => {
          this.httpLoader.hideLoader();
          this.alert.showAlertMessage(error.error);
          this.showBtnSpinner = false;
        }
      );
    } catch (error) {
      this.httpLoader.hideLoader();
      this.showBtnSpinner = false;
      console.log(error);
    }
  }


  updatePolicyFileData(payLoad){
    console.log("Upload",this.fileLLMformData.append.length);
     if(this.fileLLMformData.append.length > 0){
      this.confirmDialogService.openConfirmDialog('', {
        title:'Confirmation',
        message:'Do you want to training the LLM?',
        btnOkText:'YES',
        btnCancelText:'NO',
        onClose:(data:any)=> {
          if(data){
            this.updateFileData(payLoad,true);
          }else{
            this.updateFileData(payLoad,false);
          }
      
        }
      })
    }
    // this.sendMessageFile(formVal);
  }

  updateFileData(payLoad,trainLLM) {
      
    try {
      this.httpLoader.hideLoader();

      if (this.uploadAttachFile) {
        payLoad["file_data"] = this.preUploadedFile;
      } else {
        this.showBtnSpinner = false;
        this.alert.showAlertMessage({
          message:
            "The file could not be uploaded. Please try uploading it again",
        });
        return false;
      }

      if (payLoad["assessment"].length > 0) {
        this.is_question_found = true;

        let assessmentLength = payLoad["assessment"];

        for (let i = 0; i < assessmentLength.length; i++) {
          const el = assessmentLength[i];

          if (el.answer_type === "choice") {
            var checked = false;
            for (let k = 0; k < el.answer.length; k++) {
              const checker = el.answer[k];

              if (el.mark !== null && checker.is_correct_answer !== false) {
                checked = true;
              }
            }

            if (!checked) {
              this.alert.showAlertMessage({
                message:
                  "Please check Assessment question answers and select any answer",
              });
              // this.markOptions[i] = true;
              this.showBtnSpinner = false;
              return false;
            }
          } else {
            if (el.answer_type !== null) {
              checked = true;
            }

            if (!checked) {
              this.alert.showAlertMessage({
                message:
                  "Please check Assessment question answers and select any answer",
              });
              // this.markOptions[i] = true;
              this.showBtnSpinner = false;
              return false;
            }
          }
        }

        if (
          payLoad["random_question"] === "" ||
          payLoad["random_question"] == 0
        ) {
          this.showBtnSpinner = false;
          this.httpLoader.hideLoader();
          this.alert.showAlertMessage({
            message: "Please enter random question number value.",
          });
          return false;
        } else if (payLoad["random_question"] > assessmentLength.length) {
          this.showBtnSpinner = false;
          this.httpLoader.hideLoader();
          this.alert.showAlertMessage({
            message:
              "Please check Random question limit. Based on create assessment question",
          });
          return false;
        }
      }

      if (payLoad["survey"].length > 0) {
        this.is_survey_found = true;
      }

      if (payLoad["polls"].length > 0) {
        this.is_polls_found = true;
      }

      if (payLoad["notes"].length > 0) {
        this.is_notes_found = true;
      }

      let price = payLoad["price"] ? payLoad["price"] : 0;
      let discount = payLoad["discount"] ? payLoad["discount"] : 0;

      const userid = parseInt(window.localStorage.getItem("user_id"));

      const classList = this.selectedClassList.filter((x) => x !== userid);

      payLoad["group_id"] = parseInt(this.group_id);
      payLoad["message_id"] = this.editedMessageId;
      payLoad["is_question_found"] = this.is_question_found;
      payLoad["is_survey_found"] = this.is_survey_found;
      payLoad["is_polls_found"] = this.is_polls_found;
      payLoad["is_notes_found"] = this.is_notes_found;
      payLoad["discount"] = parseInt(discount);
      payLoad["price"] = parseFloat(price);
      payLoad["chat_user_list"] = classList;
      payLoad["is_allow_retest"] = this.isAllowRetest;
      payLoad["is_llm_training"] = trainLLM;
      this.showBtnSpinner = true;

      if(payLoad['is_publish']){

        this.chatService.updateUploadFileData(payLoad).subscribe(
          (response) => {
            this.editQuizForm.reset();
            const createdTopic = response["record"];
            const topicList = [...this.classFileList];
            for (let i = 0; i < topicList.length; i++) {
              const element = topicList[i]["message_id"];
              if (element === this.editedMessageId) {
                topicList[i] = createdTopic;
              }
            }
            topicList.unshift(
              topicList.splice(
                topicList.findIndex(
                  (topicList) => topicList.message_id === this.editedMessageId
                ),
                1
              )[0]
            );
            this.classFileList = topicList;
            this.showBtnSpinner = false;
            this.httpLoader.hideLoader();
            this.viewControl.showHideEditFileUpload = true;
            this.elibraryComponent.viewLibraryList = false;
            this.elibraryDetailComp.activeFileUploadHeader = false;
            this.editQuizForm.reset();
      
            this.preUploadedFile = null;
            this.elibraryComponent.getElibrary(0);
            this.alert.showAlertMessage({"message":"File Updated successfully"});
            this.elibraryDetailComp.getFileDetailsList("");
            this.clearFormArray();
          },
          (err) => {
            this.httpLoader.hideLoader();
            this.alert.showAlertMessage(err.error);
            this.viewControl.showHideEditFileUpload = true;
            this.elibraryComponent.viewLibraryList = false;
            this.elibraryDetailComp.activeFileUploadHeader = false;
            this.editQuizForm.reset();
      
            this.preUploadedFile = null;
            this.elibraryComponent.getElibrary(0);
            });
        
      }else{
        if(trainLLM){
          this.elibraryService.uploadLLMPdf(this.fileLLMformData).subscribe((res:any)=>{
          this.alert.showAlertMessage({"message":"LLM Model Trained Successfully"});
         });
        }
    
       this.elibraryService.uploadSummaryPdf(this.fileLLMformData).subscribe((res:any)=>{
           
          const extractText = res['result'];
         this.elibraryService.getSummarize(extractText).subscribe((sumRes:any)=>{
          let summarization = sumRes['summarization'];
            payLoad['summary'] = summarization;
  
  
            this.chatService.updateUploadFileData(payLoad).subscribe(
          (response) => {
            this.editQuizForm.reset();
            const createdTopic = response["record"];
            const topicList = [...this.classFileList];
            for (let i = 0; i < topicList.length; i++) {
              const element = topicList[i]["message_id"];
              if (element === this.editedMessageId) {
                topicList[i] = createdTopic;
              }
            }
            topicList.unshift(
              topicList.splice(
                topicList.findIndex(
                  (topicList) => topicList.message_id === this.editedMessageId
                ),
                1
              )[0]
            );
            this.classFileList = topicList;
            this.showBtnSpinner = false;
            this.httpLoader.hideLoader();
            this.viewControl.showHideEditFileUpload = true;
            this.elibraryComponent.viewLibraryList = false;
            this.elibraryDetailComp.activeFileUploadHeader = false;
            this.editQuizForm.reset();
      
            this.preUploadedFile = null;
            this.elibraryComponent.getElibrary(0);
            this.alert.showAlertMessage({"message":"File Updated successfully"});
            this.elibraryDetailComp.getFileDetailsList("");
            this.clearFormArray();
          },
          (err) => {
            this.httpLoader.hideLoader();
            this.alert.showAlertMessage(err.error);
            this.viewControl.showHideEditFileUpload = true;
            this.elibraryComponent.viewLibraryList = false;
            this.elibraryDetailComp.activeFileUploadHeader = false;
            this.editQuizForm.reset();
      
            this.preUploadedFile = null;
            this.elibraryComponent.getElibrary(0);
            });
         });
       });  
      }
     
    

     /* this.chatService.updateUploadFileData(payLoad).subscribe(
        (response) => {
          this.editQuizForm.reset();
          const createdTopic = response["record"];
          const topicList = [...this.classFileList];
          for (let i = 0; i < topicList.length; i++) {
            const element = topicList[i]["message_id"];
            if (element === this.editedMessageId) {
              topicList[i] = createdTopic;
            }
          }
          topicList.unshift(
            topicList.splice(
              topicList.findIndex(
                (topicList) => topicList.message_id === this.editedMessageId
              ),
              1
            )[0]
          );
          this.classFileList = topicList;
          this.showBtnSpinner = false;
          this.httpLoader.hideLoader();
          this.viewControl.showHideEditFileUpload = true;
          this.elibraryComponent.viewLibraryList = false;
          this.elibraryDetailComp.activeFileUploadHeader = false;
          this.editQuizForm.reset();
          this.preUploadedFile = null;
          // this.elibraryComponent.getElibrary(0);
          this.alert.showAlertMessage({"message":"File Updated successfully"});
          this.elibraryDetailComp.getFileDetailsList("");
          this.clearFormArray();
        },
        (err) => {
          this.httpLoader.hideLoader();
          this.alert.showAlertMessage(err.error);
        }
      ); */
    } catch (error) {
      this.httpLoader.hideLoader();
      console.log(error);
    }
  }

  updateFileDataMobile(payLoad) {
    try {
      this.httpLoader.hideLoader();

      if (this.uploadAttachFile) {
        payLoad["file_data"] = this.preUploadedFile;
      } else {
        this.showBtnSpinner = false;
        this.alert.showAlertMessage({
          message:
            "The file could not be uploaded. Please try uploading it again",
        });
        return false;
      }

      if (payLoad["assessment"].length > 0) {
        this.is_question_found = true;

        let assessmentLength = payLoad["assessment"];

        for (let i = 0; i < assessmentLength.length; i++) {
          const el = assessmentLength[i];
          var checked = false;
          for (let k = 0; k < el.answer.length; k++) {
            const checker = el.answer[k];

            if (el.mark !== null && checker.is_correct_answer !== false) {
              checked = true;
            }
          }

          if (!checked) {
            this.alert.showAlertMessage({
              message:
                "Please check Assessment question answers and select any answer",
            });
            // this.markOptions[i] = true;
            this.showBtnSpinner = false;
            return false;
          }
        }
      }

      if (payLoad["survey"].length > 0) {
        this.is_survey_found = true;
      }

      if (payLoad["polls"].length > 0) {
        this.is_polls_found = true;
      }

      if (payLoad["notes"].length > 0) {
        this.is_notes_found = true;
      }

      let price = payLoad["price"] ? payLoad["price"] : 0;
      let discount = payLoad["discount"] ? payLoad["discount"] : 0;

      const userid = parseInt(window.localStorage.getItem("user_id"));

      const classList = this.selectedClassList.filter((x) => x !== userid);

      payLoad["group_id"] = parseInt(this.group_id);
      payLoad["message_id"] = this.editedMessageId;
      payLoad["is_question_found"] = this.is_question_found;
      payLoad["is_survey_found"] = this.is_survey_found;
      payLoad["is_polls_found"] = this.is_polls_found;
      payLoad["is_notes_found"] = this.is_notes_found;
      payLoad["discount"] = parseInt(discount);
      payLoad["price"] = parseFloat(price);
      payLoad["chat_user_list"] = classList;

      this.showBtnSpinner = true;

      this.chatService.updateUploadFileData(payLoad).subscribe(
        (response) => {
          this.editQuizForm.reset();
          const createdTopic = response["record"];
          const topicList = [...this.classFileList];
          for (let i = 0; i < topicList.length; i++) {
            const element = topicList[i]["message_id"];
            if (element === this.editedMessageId) {
              topicList[i] = createdTopic;
            }
          }
          topicList.unshift(
            topicList.splice(
              topicList.findIndex(
                (topicList) => topicList.message_id === this.editedMessageId
              ),
              1
            )[0]
          );
          this.classFileList = topicList;
          this.showBtnSpinner = false;
          this.httpLoader.hideLoader();
          this.viewControl.showHideEditFileUpload = true;
          this.elibraryComponent.viewLibraryList = true;
          this.elibraryDetailComp.activeFileUploadHeader = false;
          this.editQuizForm.reset();
          this.preUploadedFile = null;
          // this.elibraryComponent.getElibrary(0);
          this.alert.showAlertMessage(response);
          this.clearFormArray();
        },
        (err) => {
          this.httpLoader.hideLoader();
          this.alert.showAlertMessage(err.error);
        }
      );
    } catch (error) {
      this.httpLoader.hideLoader();
      console.log(error);
    }
  }

  addOrReplace(arr, obj) {
    var index = _.findIndex(arr, { message_id: obj["message_id"] });

    if (-1 === index) {
      arr.push(obj);
    } else {
      arr[index] = obj;
    }
  }

  clearFormArray() {
    this.assQuestion().clear();
    this.surveyQuestions().clear();
    this.pollQuestions().clear();
    this.notesQuestions().clear();
  }

  getOverlayStyle() {
    const isSemi = this.semicircle;
    const transform = (isSemi ? "" : "translateY(-50%) ") + "translateX(-50%)";

    return {
      top: isSemi ? "auto" : "50%",
      bottom: isSemi ? "5%" : "auto",
      left: "50%",
      transform,
      "font-size": this.radius / 3.5 + "px",
    };
  }

  resetForms() {
    this.assessmentForm.reset();
  }

  public noWhitespaceValidator(control: FormControl) {
    const isWhitespace = (control.value || "").trim().length === 0;
    const isValid = !isWhitespace;
    return isValid ? null : { whitespace: true };
  }

  public isNumberKey(evt: any) {
    var charCode = evt.which ? evt.which : evt.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      // this.discountValidate = true;
      // this.priceErrMsg = 'Please enter discount number only. For example 12'
      this.alert.showAlertMessage({
        message:
          "Please enter discount in numbers only. For example 10% or 15%",
      });
      return false;
    } else {
      // this.discountValidate = false;
      return;
    }

    // var numberValue = event.target.value;
    // if ((event.which != 46 || numberValue.indexOf('.') != -1)&&(event.which < 48 || event.which > 57)) {
    //     if((event.which != 46 || numberValue.indexOf('.') != -1)){
    //       this.alert.showAlertMessage({message: 'Please enter discount in numbers only and two numbers allowed after the decimal point. For example: 10 or 10.5'});
    //     }
    //     event.preventDefault();
    // }

    // if(numberValue.indexOf(".")>-1 && (numberValue.split('.')[1].length > 1))        {
    //   this.alert.showAlertMessage({message: 'Price enter two numbers only allowed after decimal point'})
    //   event.preventDefault();
    // }
  }

  discountPercentage(evt) {
    let discount = evt.target.value;
    if (discount > 100) {
      this.alert.showAlertMessage({
        message: "Please enter discount min 1% to max 100%",
      });
      return false;
    } else {
      return;
    }
  }

  public numberOnlyValidator(event: any) {
    var numberValue = event.target.value;
    if (
      (event.which != 46 || numberValue.indexOf(".") != -1) &&
      (event.which < 48 || event.which > 57)
    ) {
      if (event.which != 46 || numberValue.indexOf(".") != -1) {
        this.alert.showAlertMessage({
          message:
            "Please enter a price in numbers only and two numbers allowed after the decimal point. For example: 523 or 523.36",
        });
      }
      event.preventDefault();
    }
  }

  indStudentResult(data) {
    let fileId = data.file_id;
    let userId = data.user_id;

    try {
      this.elibraryService
        .getIndividualStAssessment(fileId, userId)
        .subscribe((response) => {
          this.individualStudentRecord = response["userData"];
          this.viewControl.individualStudentResult = false;
        });
    } catch (error) {
      console.log(error.error);
    }
  }

  hideStudentResults() {
    this.individualStudentRecord = [];
    this.viewControl.individualStudentResult = true;
    this.viewControl.showIndStudentResult = true;
  }

  onClickStudentIndResult(data) {
    var answerId = data.answer_submissionId;

    var payLoad = {
      fileId: data.file_id,
      userId: data.user_id,
      answerId: data.answer_submissionId,
    };

    this.studentIds = data.user_id;
    this.fileIds = data.file_id;

    try {
      this.elibraryService
        .getSudentsAssessmentResult(payLoad)
        .subscribe((response) => {
          this.viewStudentQA = response["result"];
          console.log(this.viewStudentQA);

          this.viewStudentQA.map((el) => {
            this.studentRemarkForm.patchValue({
              question_id: el.question_id,
              remarks: el.teacher_remark,
              mark: el.teacher_mark,
            });
          });

          this.viewControl.showIndStudentResult = false;
        });
    } catch (error) {
      console.log(error.error);
    }
  }

  removeIndStudentResult() {
    this.viewStudentQA = [];
    this.viewControl.showIndStudentResult = true;
  }

  IsDecimalValue(el) {
    var ex = /^\s*\d*\.?\d{0,2}\s*$/;

    if (ex.test(el.target.value) === false) {
      el.target.value = el.target.value.substring(
        0,
        el.target.value.length - 1
      );
      // this.alert.showAlertMessage({message: 'Price enter two numbers only allowed after decimal point'})
    }
    if (ex.test(el.target.value) === false) {
      el.target.value = "";
    }
  }
  /**
   * After open the user message update message delivered and read
   * @param messageList
   */
  updateMessageRead(messageList) {
    const logUserId = this.loggedUserId;
    if (messageList && messageList.length > 0) {
      const filterMsgList = _.filter(messageList, function (eachMsg) {
        return eachMsg.from_user_id !== logUserId;
      });
      const payLoad = _.pick(
        _.last(filterMsgList),
        "group_id",
        "message_id",
        "from_user_id",
        "to_user_id",
        "user_id",
        "is_read"
      );
      if (payLoad.message_id && !payLoad.is_read) {
        this.chatService.updateMessageRead(payLoad).subscribe(
          (response) => {
            if (response && response["user_id"] !== 0) {
              // this.chatListComponent.chatList.find((user): any => {
              //     if (response['user_id'] === user.user_id) {
              //         return user.unread_msg_count = 0;
              //     }
              // });
            }
            if (response && response["group_id"] !== 0) {
              // this.chatListComponent.chatList.find((user): any => {
              //     if (response['group_id'] === user.group_id) {
              //         return user.unread_msg_count = 0;
              //     }
              // });
            }
          },
          (error) => {
            console.log(error);
          }
        );
      }
    }
  }

  changeEventUserNotes(data, formFields) {
    for (let i = 0; i < this.userNotes.length; i++) {
      const element = this.userNotes[i];
      if (element.question_id === data.question_id) {
        element["notes"] = formFields["notes"];

        this.studentNotesRecord.push(element);
      }
    }
  }

  submitUserNotes(formValue, msgId) {
    try {
      this.showBtnSpinner = true;
      this.httpLoader.showLoader();

      var payLoad: any;
      var rest: any;

      if (this.studentNotesRecord.length > 0) {
        payLoad = { notes: this.studentNotesRecord };
      } else {
        if (formValue.question_id != "" && formValue.user_notes_id != "") {
          rest = { question_id: formValue.question_id, notes: formValue.notes };
        } else {
          rest = { notes: formValue.notes };
        }
        payLoad = { notes: new Array(rest) };
      }

      this.chatService.submitUserNotes(payLoad, msgId).subscribe(
        (response) => {
          this.showBtnSpinner = false;
          this.httpLoader.hideLoader();
          this.studentNotesRecord = [];
          this.chatService
            .getLoggedUserNotes(msgId)
            .subscribe((response: any) => {
              this.userNotes = response.notes;

              this.isNotesLength = response.notes.length;

              this.userNotesForm.patchValue({
                user_notes_id: response.notes[0].user_notes_id
                  ? response.notes[0].user_notes_id
                  : "",
                notes: response.notes[0]["notes"]
                  ? response.notes[0]["notes"]
                  : "",
              });
            });
          this.alert.showAlertMessage(response);
        },
        (error) => {
          this.httpLoader.hideLoader();
          this.showBtnSpinner = false;
          this.alert.showAlertMessage(error.error);
        }
      );
    } catch (error) {
      this.httpLoader.hideLoader();
      this.showBtnSpinner = false;
      console.log(error);
    }
  }

  updatedUserNotes(formValue, msgIds) {
    try {
      this.showBtnSpinner = true;
      this.httpLoader.showLoader();

      var payLoad: any;
      var rest: any;

      if (this.studentNotesRecord.length > 0) {
        payLoad = { notes: this.studentNotesRecord };
      } else {
        if (formValue.question_id != "") {
          rest = {
            question_id: formValue.question_id,
            user_notes_id: formValue.user_notes_id,
            notes: formValue.notes,
          };
        } else {
          rest = {
            user_notes_id: formValue.user_notes_id,
            notes: formValue.notes,
          };
        }
        payLoad = { notes: new Array(rest) };
      }

      this.chatService.updateUserNotes(payLoad, msgIds).subscribe(
        (response) => {
          this.showBtnSpinner = false;
          this.httpLoader.hideLoader();
          this.studentNotesRecord = [];
          this.chatService
            .getLoggedUserNotes(msgIds)
            .subscribe((response: any) => {
              this.userNotes = response.notes;

              this.isNotesLength = response.notes.length;

              this.userNotesForm.patchValue({
                user_notes_id: response.notes[0].user_notes_id
                  ? response.notes[0].user_notes_id
                  : "",
                notes: response.notes[0]["notes"]
                  ? response.notes[0]["notes"]
                  : "",
              });
            });
          this.alert.showAlertMessage(response);
        },
        (error) => {
          this.httpLoader.hideLoader();
          this.showBtnSpinner = false;
          this.alert.showAlertMessage(error.error);
        }
      );
    } catch (error) {
      this.httpLoader.hideLoader();
      this.showBtnSpinner = false;
      console.log(error);
    }
  }

  userNotesDownload(data) {
    try {
      this.viewControl.hideExistingFileView = false;
      this.elibraryComponent.viewLibraryList = true;

      let particulaData = data.map((el) => {
        return `${el.question ? el.question : ""}'\n'${el.notes}'\n'`;
      });

      let $dataConvert = particulaData.toString();
      $dataConvert = $dataConvert.replace(/["',]/g, " ");

      var FileSaver = require("file-saver");
      var blob = new Blob([$dataConvert], { type: "text/plain;charset=utf-8" });
      FileSaver.saveAs(blob, "notes.doc");
    } catch (error) {
      console.log(error);
    }
  }

  changeEvtTeacherRemark(data, record) {
    var data1: any;
    for (let i = 0; i < this.viewStudentQA.length; i++) {
      const element = this.viewStudentQA[i];
      if (element.question_id === data.question_id) {
        element["remarks"] = record["remarks"]
          ? record["remarks"]
          : data.teacher_remark;
        // element['mark'] = record['mark'];
        data1 = {
          question_id: element.question_id,
          teacher_remark: element.remarks,
          mark: parseInt(element.mark),
        };
        this.teachRemarksData.push(data1);
      }
    }
  }

  changeRemarksMark(data, result) {
    var formData: any;
    for (let i = 0; i < this.viewStudentQA.length; i++) {
      const element = this.viewStudentQA[i];
      if (element.question_id === data.question_id) {
        element["mark"] = result["mark"];
        formData = {
          question_id: element.question_id,
          teacher_remark: element.teacher_remark ? element.teacher_remark : "",
          mark: parseInt(element.mark),
        };
        this.teachRemarkMarkData.push(formData);
      }
    }
  }

  submitRemarks(data, formValue) {
    try {
      var payLoad: any;
      var rest: any;

      if (
        this.teachRemarkMarkData.length > 0 &&
        this.teachRemarksData.length > 0
      ) {
        payLoad = {
          file_id: this.fileIds,
          student_id: this.studentIds,
          remarks_data: this.teachRemarkMarkData,
        };
      }
      this.showBtnSpinner = true;
      this.httpLoader.showLoader();
      this.elibraryService.postTeacherRemark(payLoad).subscribe(
        (response: any) => {
          this.viewControl.showIndStudentResult = false;
          this.showBtnSpinner = false;
          this.httpLoader.hideLoader();
          this.alert.showAlertMessage(response);
          this.studentRemarkForm.reset();
        },
        (error) => {
          this.httpLoader.hideLoader();
          this.alert.showAlertMessage(error);
        }
      );
    } catch (error) {
      console.log(error);
    }
  }

  onclickViewRemarks(data) {
    try {
      this.httpLoader.showLoader();
      const payLoad = {
        fileId: data.file_id,
        answerId: data.answer_submissionId,
        userId: data.user_id,
      };

      this.elibraryService
        .getSudentsAssessmentResult(payLoad)
        .subscribe((response: any) => {
          this.httpLoader.hideLoader();
          this.viewControl.viewTeacherRemarks = true;
          this.viewTeacherRemarks = response.result;

          for (let i = 0; i < this.viewTeacherRemarks.length; i++) {
            const element = this.viewTeacherRemarks[i];
            this.teacherMark += parseInt(element.test_mark);
            this.testOverallMarks += parseInt(element.teacher_mark);
          }
          this.overallmarks = this.testOverallMarks * (100 / this.teacherMark);
        });
    } catch (error) {
      console.log(error);
    }
  }

  removeTeacherRemark() {
    this.viewControl.viewTeacherRemarks = false;
    this.viewControl.showAssessmentMarks = false;
  }

  addVideoMarker(positions, vDuration) {
    var totalDuration = vDuration;
    var getActlSecs:string;
    var seekbar = positions.map(function (position) {
      var h = Math.floor(position / 3600)
        .toString()
        .padStart(2, "0"),
      m = Math.floor((position % 3600) / 60)
        .toString()
        .padStart(2, "0"),
      s = Math.floor(position % 60)
        .toString()
        .padStart(2, "0");

    if (h!='00' && m!='00' && s!='00') {
      getActlSecs = h + ":" + m + ":" + s;
    }else {
      getActlSecs = m + ":" + s;
    }


      var seekTime = getActlSecs;

      // Is position within range of the duration?
      if (seekTime <= totalDuration) {
        const [hours, minutes] = seekTime.split(":");
        const duration = +hours * 60 * 60 + +minutes * 60;
        const [h, m] = totalDuration.split(":");
        const totdur = +h * 60 * 60 + +m * 60;
        // Calculate position in percentage..
        const left = (duration / totdur) * 100 + "%";

        return left;
      }
    });

    this.markerSeekBar = seekbar;
  }

  msToTime(milli) {
    // Pad to 2 or 3 digits, default is 2
    var milliseconds = milli % 1000;
    var seconds = Math.floor((milli / 1000) % 60);
    var minutes = Math.floor((milli / (60 * 1000)) % 60);

    return minutes + ":" + seconds + "." + milliseconds;
  }


  dragEntered(event: CdkDragEnter<number>) {
    const drag = event.item;
    const dropList = event.container;
    const dragIndex = drag.data;
    const dropIndex = dropList.data;

    this.dragDropInfo = { dragIndex, dropIndex };
    console.log('dragEntered', { dragIndex, dropIndex });

    const phContainer = dropList.element.nativeElement;
    const phElement = phContainer.querySelector('.cdk-drag-placeholder');

    if (phElement) {
      phContainer.removeChild(phElement);
      phContainer.parentElement?.insertBefore(phElement, phContainer);

      moveItemInArray(this.classFileList, dragIndex, dropIndex);
    }
  }

  dragMoved(event: CdkDragMove<number>) {
    if (!this.dropListContainer || !this.dragDropInfo) return;

    const placeholderElement =
      this.dropListContainer.nativeElement.querySelector(
        '.cdk-drag-placeholder'
      );

    const receiverElement =
      this.dragDropInfo.dragIndex > this.dragDropInfo.dropIndex
        ? placeholderElement?.nextElementSibling
        : placeholderElement?.previousElementSibling;

    if (!receiverElement) {
      return;
    }

    receiverElement.style.display = 'none';
    this.dropListReceiverElement = receiverElement;
  }

  dragDropped(event: CdkDragDrop<number>) {
    if (!this.dropListReceiverElement) {
      return;
    }

    this.dropListReceiverElement.style.removeProperty('display');
    this.dropListReceiverElement = undefined;
    this.dragDropInfo = undefined;
  }


  Published(message_id){


    try {
      this.confirmDialogService.openConfirmDialog('',{
        title:'Confirmation',
        message:'Do you want to publish this policy.',
        btnOkText:'YES',
        btnCancelText:'NO',
        onClose: (data:any) => {
          if(data){
            const payLoad = {
              group_id: parseInt(this.group_id),
              file_id: message_id,
            };
        
           this.chatService.editUploadFileList(payLoad).subscribe(
              (data: any) => {
                 
                 let payLoad = data['record'];
                 payLoad['is_publish'] = true;
                 this.editedData = data.record;
                 this.editedMessageId = data.record ? data.record.message_id : 0;
                 this.updateFileData(payLoad,false);
              });
          }
        }	  
    });
       
    } catch (error) {
      this.httpLoader.hideLoader();
      console.log(error);
    }

   
  }


  showSummarize(message_id){
  

    
      const payLoad = {
        group_id: parseInt(this.group_id),
        file_id: message_id,
      };
     this.chatService.editUploadFileList(payLoad).subscribe(
        (data: any) => {
           console.log("get data",data);
           if(data['success']){
            this.SummarizationText = data['record']['summary'];
             this.showSummarizationPopup=true;
           this.showPaginateSpinner = false;
           }
        });
 
     
  }

  closeSummarization(){
    this.SummarizationText="";
    this.showSummarizationPopup =!this.showSummarizationPopup;
  }

  ngOnDestroy(): void {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.
    this.inputSrc = null;
    this.videoUrls = null;

    // window.removeEventListener('focus', this.focusHandler, false);
  }
}
