import { DOCUMENT } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, Inject, Input, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { AlertPopup, path } from 'app/app.config';
import { ChatService } from 'app/components/chat-list/chat.service';
import { jsPDF } from "jspdf";
import { AssistBotService } from '../../assist-bot.service';
import { DialogService } from 'app/services/dialog.service';
import { ListdialogComponent } from 'app/core/listdialog/listdialog.component';
 
declare const $:any;

@Component({
  selector: 'app-bot',
  templateUrl: './bot.component.html',
  styleUrls: ['./bot.component.scss']
})
export class BotComponent {
  profileUrl = path.PROFILE_URL;
  volumeicons:any=["fa fa-volume-off","fa fa-volume-up"];
  @Input() data;
  @Input() userDetail;
  @Input() index;
  @Input() speakeLanguage;
  @Output() copyText:EventEmitter<any>=new EventEmitter();
  @Output() PingChat:EventEmitter<any>=new EventEmitter();
  @Output() Like:EventEmitter<any>=new EventEmitter();
  @Output() Dislike:EventEmitter<any>=new EventEmitter();
  showPaginateSpinner:boolean=false;

  public isActiveFeedback: boolean = false;

  synth = window.speechSynthesis;

  commentForm:FormGroup=new FormGroup({});
  showuserIcon:boolean=false;

 // base_url:any = `http://115.246.210.90:8502`;
   llm_url:any;
  constructor(private fb:FormBuilder,@Inject(DOCUMENT) private document:Document,private alert: AlertPopup,private http:HttpClient,private chatService: ChatService,private assistBotService:AssistBotService,private dialogService:DialogService){

  }

  ngOnInit(){
    
  this.llm_url = this.assistBotService.GptConfiguration['LLm_url'];
    
    this.commentForm = this.fb.group({
      'comment':new FormControl('',Validators.required)
    });
     // this.data['answer']='The California Paid Family Leave (PFL) policy is a state disability insurance program that provides wage replacement benefits to eligible workers who need to take time off work to care for a seriously ill family member or to bond with a new child. The policy covers various family members including children, parents, parent-in-laws, grandparents, grandchildren, siblings, spouses, and registered domestic partners. There is no waiting period or relapse period for the PFL policy. '
   this.showPaginateSpinner= true;
   if(this.data.bot_answer){
    this.showPaginateSpinner = false;
   }

    let headers:any = new Headers();
    headers.append('Content-Type', 'application/json');

    if(this.data?.search && this.data?.bot_type!=="suggestion"){

      this.showPaginateSpinner= true;
      const payload = {
        "query":this.data.question,
        "clientid":localStorage.getItem('company_id')
      }

      this.http.post( this.llm_url+"query", payload, {headers: headers}).subscribe((response:any)=>{
      
        this.showPaginateSpinner= false;
        this.data['bot_answer']=response['result'];
        let user_id = window.localStorage.getItem('user_id');
        let botPayload ={
        "user_id":user_id,
        "question":payload["query"],
        "answer":response['result'],
        "question_type":"history",
        "bot_type":"bot",
        "sequence_id":new Date().valueOf()
        };
        this.assistBotService.createHistory(botPayload).subscribe((result:any)=>{
          // console.log("savehistory",result);
          if(result['success']){
          //  console.log("bot",this.data);
            this.data["question_type"]="history";
            this.data["sequence_id"]=result['createdMessage']["sequence_id"];
            this.data["history_id"] = result['createdMessage']["history_id"];
            this.data["created_at"] = new Date();
            this.data["search"] = false;
            this.assistBotService.chatHistoryList.unshift(this.data);
            this.assistBotService.historyListBheaviour.next({"templateName":"bot"});
          }
         
        });
       
      },(error:any)=>{
        this.showPaginateSpinner= false;
      });
   
    
    } 


   if(this.data?.bot_type=="suggestion" && this.data?.search){
   
    this.data["search"] = false;


    let user_id = window.localStorage.getItem('user_id');
    let botPayload ={
    "user_id":user_id,
    "question":this.data['question'],
    "answer":this.data['bot_answer'],
    "question_type":"history",
    "bot_type":"suggestion",
    "sequence_id":new Date().valueOf()
    };
    this.assistBotService.createHistory(botPayload).subscribe((result:any)=>{
       if(result['success']){
       this.data["question_type"]="history";
        this.data["sequence_id"]=result['createdMessage']["sequence_id"];
        this.data["history_id"] = result['createdMessage']["history_id"];
        this.data["created_at"] = new Date();
        this.data["search"] = false;
        this.assistBotService.chatHistoryList.unshift(this.data);
        this.assistBotService.historyListBheaviour.next({"templateName":"suggestion"});
      }
     
    });
 
   }


  }

  speakText(state:boolean,textdata:string){

    let voices = this.synth.getVoices();

    let speakLanguage:any, defaultVoice:any;

    if(this.speakeLanguage === 'hindi'){
      speakLanguage = 'Google हिन्दी';
      defaultVoice = 'Microsoft Kalpana - Hindi (India)';
    }else if(this.speakeLanguage === 'spanish'){
      speakLanguage = 'Google español';
      defaultVoice = 'Microsoft Helena - Spanish (Spain)';
    }else{
      speakLanguage = 'Google UK English Female';
      defaultVoice = 'Microsoft Heera - English (India)';
    }

     let botVoice = voices.findIndex(
      (items) => {
        items.name === speakLanguage
      }
    );
    let botDefaultVoice = voices.findIndex(
      (items) => {
        items.name === defaultVoice}
    );


    let text = textdata.replace(/(<([^>]+)>)/gi, "");
    if ('speechSynthesis' in window) {
       var utterThis = new SpeechSynthesisUtterance(text);

       utterThis.voice =
      botVoice > -1 ? voices[botVoice] : voices[botDefaultVoice];
       if(state){
        speechSynthesis.cancel();
        if (this.speakeLanguage === 'hindi') {
          utterThis.lang = 'hi-IN'
        }else if(this.speakeLanguage === 'spanish'){
          utterThis.lang = 'es-ES'
        }else{
          utterThis.lang = 'en-IN'
        }
        // utterThis.lang = this.speakeLanguage === 'hindi' ? 'hi-IN' : this.speakeLanguage;
        // console.log(utterThis, 'speach');

        window.speechSynthesis.speak(utterThis);
       }else{
        speechSynthesis.cancel();
       }

     } else {
         console.log('not support ');
     }
}

showFeedbackform(){
  this.isActiveFeedback =!this.isActiveFeedback;
  console.log( this.isActiveFeedback, 'isActive');
  
}

submitForm(){
  this.alert.showAlertMessage({"message":"Thanks for your feedback."});
     $('#inputField').focus();
}

 copiedText(index:any){
  this.copyText.emit(index);
}

PingedChat(index:any){
  this.PingChat.emit(index);
 
}

Liked(index:any){
  this.Like.emit(index);
}

Disliked(index:any){
  this.Dislike.emit(index);
}

saveComment(items:any){
  console.log("questionId",items.question);
  console.log("formdata",this.commentForm.value);
 
  let message = "<b>Question: </b>"+items.question+"? <br /><b>Comments: </b> "+this.commentForm.value['comment'];
  const refId = new Date().valueOf();
   
 
const payLoad = {"group_id":345,"message":message,"unique_ref_id":refId};
this.chatService.sendGroupMessage(payLoad).subscribe(
    (messageRes) => {
      if (messageRes['createdMessage']) {
        this.alert.showAlertMessage({'message':'Your comments saved'});
      }
    });
//   Array.from(document.querySelectorAll('.comment-area-box')).map((el)=> {
//     el.classList.remove('is-active');
//  });

}

cancelComment(){
  Array.from(document.querySelectorAll('.comment-area-box')).map((el)=> {
    el.classList.remove('is-active');
 });
}


onKeypressEvent(event: any) {
 let comment = event.target.value;
 
  if ((event.code == "Enter") || (event.code == "NumpadEnter")) {
     console.log("comment",comment);
  }
}
downloadPDF(items:any){

  var doc = new jsPDF();
  var img = new Image()
  img.src = '../assets/images/absence_gpt.png';
  doc.addImage(img,'png',0,0,100,25);
  doc.setFont("sans-serif");
  doc.setTextColor('#000');
  doc.setFontSize(14);
  doc.text("Question:"+items.question,15,30);
  doc.setTextColor('#000');
  doc.setFontSize(11);
  
  items.bot_answer = items.bot_answer.replace(/<br\s*[\/]?>/gi, "\n");
  var splitTitle = doc.splitTextToSize(items.bot_answer,185);
 doc.text(splitTitle,15,38);
  
  
  var userId = window.localStorage.getItem('user_id');
   
    doc.save(userId+'_'+'assign'+new Date().getTime()+'.pdf');
    var blob = doc.output('blob');
    this.alert.showAlertMessage({'message':'Answer downloaded'});
    console.log(blob);
}

changeNewLine(answer){
    return answer.replace(/\n\r?/g, '<br />');
}

ngAfterViewInit(){
    
  // if(this.userDetail['user_icon'] !==null){
  //   this.showuserIcon=true;
  // }
}


refresh(){
  this.data['created_at'] = new Date();
console.log("bot data",this.data);


    this.showPaginateSpinner= true;
  // if(this.data.bot_answer){
  //  this.showPaginateSpinner = false;
  // }

   let headers:any = new Headers();
   headers.append('Content-Type', 'application/json');

   if(this.data?.bot_type!=="suggestion"){

     this.showPaginateSpinner= true;
     const payload = {
       "query":this.data.question,
       "clientid":localStorage.getItem('company_id')
     }

     this.http.post( this.llm_url+"query", payload, {headers: headers}).subscribe((response:any)=>{
     
       this.showPaginateSpinner= false;
       this.data['bot_answer']=response['result'];
       let user_id = window.localStorage.getItem('user_id');
       let botPayload ={
        "question":payload["query"],
        "bot_answer":response['result'],
        "question_type":"history",
        "bot_type":"bot",
        "sequence_id":this.data.sequence_id
       }
       let params={
        history_id:this.data.history_id,
        user_id:user_id
       }
       this.assistBotService.RefreshBotData(botPayload,params).subscribe((result:any)=>{
         // console.log("savehistory",result);
         if(result['success']){
         //  console.log("bot",this.data);
           this.data["question_type"]="history";
          // this.data["sequence_id"]=result['createdMessage']["sequence_id"];
           //this.data["history_id"] = result['createdMessage']["history_id"];
           this.data["created_at"] = new Date();
           this.data["search"] = false;
           this.alert.showAlertMessage({"message":"Data refreshed successfully"});
          //  this.assistBotService.chatHistoryList.unshift(this.data);
          //  this.assistBotService.historyListBheaviour.next({"templateName":"bot"});
         }
        
       });
      
     },(error:any)=>{
       this.showPaginateSpinner= false;
     });
  
   
   } 


  // if(this.data?.bot_type=="suggestion" && this.data?.search){
  
  //  this.data["search"] = false;


  //  let user_id = window.localStorage.getItem('user_id');
  //  let botPayload ={
  //  "user_id":user_id,
  //  "question":this.data['question'],
  //  "answer":this.data['bot_answer'],
  //  "question_type":"history",
  //  "bot_type":"suggestion",
  //  "sequence_id":new Date().valueOf()
  //  };
  //  this.assistBotService.createHistory(botPayload).subscribe((result:any)=>{
  //     if(result['success']){
  //     this.data["question_type"]="history";
  //      this.data["sequence_id"]=result['createdMessage']["sequence_id"];
  //      this.data["history_id"] = result['createdMessage']["history_id"];
  //      this.data["created_at"] = new Date();
  //      this.data["search"] = false;
  //      this.assistBotService.chatHistoryList.unshift(this.data);
  //      this.assistBotService.historyListBheaviour.next({"templateName":"suggestion"});
  //    }
    
  //  });

  // }





}

openDialog(){

  this.dialogService.openListDialog(ListdialogComponent,{
    title:'Create Document',
    data:[
      {"label":"Save as a word document","value":"word"},{"label":"Save as a pdf file ","value":"pdf"}],
    onclose: (selectedList) => {
       if(selectedList){
        let user_id = window.localStorage.getItem('user_id');
        let payload = {
         "user_id":parseInt(user_id),
         "question":this.data.question,
         "answer":this.data.bot_answer,
         "question_type":selectedList['value'],
         "bot_type": this.data['bot_type'],
         "sequence_id":new Date().valueOf()
        }
        console.log('file  generate payload',payload);
        this.assistBotService.BotFilegenerate(payload).subscribe((res:any)=>{
           console.log("file generate",res);
          if(res['success']){
            this.alert.showAlertMessage({'message':'File generated successfully'});
            this.assistBotService.chatHistoryList.unshift(res['result']);
            this.assistBotService.historyListBheaviour.next({"templateName":this.data['bot_type']});
          }else{
            this.alert.showAlertMessage(res);
            this.assistBotService.historyListBheaviour.next({"templateName":this.data['bot_type']});
          }
        },(error:any)=>{
          console.log("file generate error",error);
         // this.alert.showAlertMessage(error['error']);
         this.alert.showAlertMessage({'message':'File already exists','type':2});
          this.assistBotService.historyListBheaviour.next({"templateName":this.data['bot_type']});
        });
       }
    
    }
   });

}

generateAudio(){
  let user_id = window.localStorage.getItem('user_id');
  let payload = {
   "user_id":parseInt(user_id),
   "question":this.data.question,
   "answer":this.data.bot_answer,
   "question_type":"audio",
   "bot_type": this.data['bot_type'],
   "sequence_id":new Date().valueOf()
  }

  this.assistBotService.BotFilegenerate(payload).subscribe((res:any)=>{
    console.log("audio file generate",res);
    if(res['success']){
      this.alert.showAlertMessage({'message':'Audio File generated successfully'});
      this.assistBotService.chatHistoryList.unshift(res['result']);
      this.assistBotService.historyListBheaviour.next({"templateName":this.data['bot_type']});
    }else{
      this.alert.showAlertMessage({'message':'Audio File already exists','type':2});
      this.assistBotService.historyListBheaviour.next({"templateName":this.data['bot_type']});
    }
  },(error:any)=>{
    console.log("file generate error",error);
    // this.alert.showAlertMessage(error['error']);
    this.alert.showAlertMessage({'message':'Audio File already exists','type':2});
     this.assistBotService.historyListBheaviour.next({"templateName":this.data['bot_type']});
  });
}




}
